@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@font-face {
  font-family: "trajan_proregular";
  src: url("../fonts/trajan_pro-webfont.woff2") format("woff2"),
    url("../fonts/trajan_pro-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "trajan_probold";
  src: url("../fonts/trajanpro-bold-webfont.woff2") format("woff2"),
    url("../fonts/trajanpro-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "trajan_proregular";
  src: url("../fonts/trajanpro-regular-webfont.woff2") format("woff2"),
    url("../fonts/trajanpro-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_nova_altbold";
  src: url("../fonts/proxima_nova_alt_bold-webfont.woff2") format("woff2"),
    url("../fonts/proxima_nova_alt_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_novasemibold";
  src: url("../fonts/proximanova-semibold-webfont.woff2") format("woff2"),
    url("../fonts/proximanova-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_nova_altlight";
  src: url("../fonts/proxima_nova_alt_light-webfont.woff2") format("woff2"),
    url("../fonts/proxima_nova_alt_light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_nova_altthin";
  src: url("../fonts/proxima_nova_alt_thin-webfont.woff2") format("woff2"),
    url("../fonts/proxima_nova_alt_thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_novablack";
  src: url("../fonts/proxima_nova_black-webfont.woff2") format("woff2"),
    url("../fonts/proxima_nova_black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_novabold";
  src: url("../fonts/proxima_nova_bold-webfont.woff2") format("woff2"),
    url("../fonts/proxima_nova_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_novaextrabold";
  src: url("../fonts/proxima_nova_extrabold-webfont.woff2") format("woff2"),
    url("../fonts/proxima_nova_extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_novathin";
  src: url("../fonts/proxima_nova_thin-webfont.woff2") format("woff2"),
    url("../fonts/proxima_nova_thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_novaregular";
  src: url("../fonts/proximanova-regular-webfont.woff2") format("woff2"),
    url("../fonts/proximanova-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font: inherit;
  transition: 0.3s ease all;
}

*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-weight: normal;
  line-height: normal;
  font-size: 18px;
  font-family: "proxima_novaregular";
  background-color: #f3f3f3;
}

.body {
  position: relative;
  font-family: "Poppins";
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

button,
input[type="submit"],
input[type="reset"],
a {
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  text-decoration: none;
  outline: none;
}

a:hover,
a:focus {
  text-decoration: none;
}

::-moz-selection {
  color: #fff;
  background: #b07f35;
}

::selection {
  color: #fff;
  background: #b07f35;
}

img {
  max-width: 100%;
  display: block;
  height: auto;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: normal;
  font-weight: normal;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}

/***************** FOR BOOTSTRAP ******************/
.container {
  max-width: 1560px;
  margin: auto;
  padding: 0 20px;
}

.navbar {
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
}

.nav > li > a:hover,
.nav > li > a:focus {
  background-color: transparent;
}

.navbar,
.nav-link {
  padding: 0;
  margin: 0;
}

.navbar-expand-md .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.nav > li > a:hover,
.nav > li > a:focus {
  background-color: transparent;
}

/*As Per Design Change*/

.cus_nav_outr {
  padding: 20px 0px 0px 20px;
}

.cus_nav_innr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right_nav {
  display: flex;
  align-items: center;
}

/*Header*/

.top_header {
  padding: 0;
}

/*zoom effect*/

.zoom_otr {
  overflow: hidden;
}

.bnr_pic {
  overflow: hidden;
}

.zoom {
  -webkit-animation: scale 20s linear infinite;
  animation: scale 20s linear infinite;
}

@-webkit-keyframes scale {
  50% {
    transform: scale(1.2);
  }
}

@keyframes scale {
  50% {
    transform: scale(1.2);
  }
}

/*Zoom effect*/

.bnr_content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.bnr_otr {
  position: relative;
  overflow: hidden;
}

/* Banner End */

/* cmn_margin & Padding */

.mt-40 {
  margin-top: 40px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-40 {
  margin-bottom: 40px;
}

.cmn_pdding {
  padding: 8rem 0;
}

/*----Common Header and Button----*/

.cmn_hdr h3 {
  font-size: 6rem;
  line-height: 1;
  color: #141414;
  padding-bottom: 10px;
  font-family: "proxima_novaregular";
}

.cmn_hdr.wht h2 {
  color: #fff;
}

.cmn_hdr h6 {
  color: #2b2b2b;
  font-size: 26px;
  letter-spacing: 1px;
  line-height: 1.5;
  padding-bottom: 10px;
  text-transform: capitalize;
}

.cmn_hdr p {
  font-size: 20px;
  color: #3d565b;
  line-height: 1.5;
  padding-bottom: 10px;
}

.cmn_hdr.wht p {
  color: #fff;
}

.cmn_btn {
  font-size: 18px;
  color: #fff;
  padding: 15px 30px;
  display: inline-block;
  background: #e0c15a;
  border: 2px solid #e0c15a;
  position: relative;
  text-transform: uppercase;
  transition: 0.5s all;
  z-index: 1;
  font-weight: 600;
  overflow: hidden;
}

.cmn_btn::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.7s;
}

.cmn_btn:hover {
  color: #e0c15a;
  border: 2px solid #e0c15a;
  background-color: #fff;
}

.cmn_btn:hover::after {
  width: 100%;
}
.pagination_btn {
  font-size: 18px;
  color: #e0c15a;
  padding: 15px 30px;
  display: inline-block;
  /* background: #e0c15a; */
  border: 2px solid #e0c15a;
  position: relative;
  text-transform: uppercase;
  /* transition: 0.5s all; */
  z-index: 1;
  font-weight: 600;
  overflow: hidden;
}
.activepgbtn {
  background: #e0c15a;
  color: #fff;
}
/*----Common Header and Button End----*/

/*! button back to top !*/

#backToTop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  background-color: #ed1c24;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 50px;
  position: fixed;
  right: 2.5rem;
  bottom: 105%;
  cursor: pointer;
  z-index: 5555;
  transition: 0.5s all ease;
  border: 1px solid #ed1c24;
  font-size: 1.8rem;
  transition: 0.8s all;
}

#backToTop.show {
  bottom: 2.5rem;
}

/*! button back to top End!*/

/* Animation Section */

/* Swing */

.swing:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  top: -14px;
  left: 54%;
  z-index: 5;
  border-radius: 50% 50%;
  background: #000;
}

@-webkit-keyframes swing {
  0% {
    transform: rotate(3deg);
  }

  100% {
    transform: rotate(-3deg);
  }
}

@keyframes swing {
  0% {
    transform: rotate(3deg);
  }

  100% {
    transform: rotate(-3deg);
  }
}

.swing:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  top: -10px;
  left: 50%;
  z-index: 0;
  border-bottom: none;
  border-right: none;
  transform: rotate(45deg);
}

.swing {
  -webkit-animation: swing ease-in-out 1s infinite alternate;
  animation: swing ease-in-out 1s infinite alternate;
  transform-origin: center -20px;
}

/* Rotate */

.rotate {
  -webkit-animation: rotation 12s infinite linear;
  animation: rotation 12s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

/* Floating */

.floating {
  -webkit-animation: floating ease-in-out 3s infinite;
  animation: floating ease-in-out 3s infinite;
}

@-webkit-keyframes floating {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 15px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 15px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

/* Animation Section End */
.mainsection .content {
  margin-left: 23rem;
}

#checkout-progress {
  width: 100%;
  margin: 0px auto;
  font-size: 2.5rem;
  font-weight: 900;
  position: relative;
}

@media (max-width: 767px) {
  #checkout-progress {
    font-size: 1.5em;
  }
}

#checkout-progress:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 8px;
  width: 100%;
  background-color: #ccc;
  -webkit-transform: translateY(-50%) perspective(1000px);
  transform: translateY(-50%) perspective(1000px);
}

#checkout-progress:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 8px;
  width: 100%;
  background: rgb(182, 135, 56);
  background: -moz-linear-gradient(
    90deg,
    rgba(182, 135, 56, 1) 0%,
    rgba(231, 196, 84, 1) 52%,
    rgba(169, 119, 49, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(182, 135, 56, 1) 0%,
    rgba(231, 196, 84, 1) 52%,
    rgba(169, 119, 49, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(182, 135, 56, 1) 0%,
    rgba(231, 196, 84, 1) 52%,
    rgba(169, 119, 49, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b68738", endColorstr="#a97731", GradientType=1);
  -webkit-transform: scaleX(0.2) translateY(-50%) perspective(1000px);
  transform: scaleX(0.2) translateY(-50%) perspective(1000px);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

#checkout-progress.step-2:after {
  -webkit-transform: scaleX(0.4) translateY(-50%) perspective(1000px);
  transform: scaleX(0.4) translateY(-50%) perspective(1000px);
}

#checkout-progress.step-3:after {
  -webkit-transform: scaleX(0.6) translateY(-50%) perspective(1000px);
  transform: scaleX(0.6) translateY(-50%) perspective(1000px);
}

#checkout-progress.step-4:after {
  -webkit-transform: scaleX(0.8) translateY(-50%) perspective(1000px);
  transform: scaleX(0.8) translateY(-50%) perspective(1000px);
}

#checkout-progress.step-5:after {
  -webkit-transform: scaleX(1) translateY(-50%) perspective(1000px);
  transform: scaleX(1) translateY(-50%) perspective(1000px);
}

#checkout-progress.step-6:after {
  -webkit-transform: scaleX(1.2) translateY(-50%) perspective(1000px);
  transform: scaleX(1.2) translateY(-50%) perspective(1000px);
}

#checkout-progress .progress-bar {
  width: 100%;
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
}

#checkout-progress .progress-bar .step {
  z-index: 2;
  position: relative;
}

#checkout-progress .progress-bar .step .step-label {
  position: absolute;
  top: calc(100% + 25px);
  left: 50%;
  -webkit-transform: translateX(-50%) perspective(1000px);
  transform: translateX(-50%) perspective(1000px);
  white-space: nowrap;
  font-size: 0.4em;
  font-weight: 600;
  color: #ccc;
  transition: 0.3s ease;
}

@media (max-width: 767px) {
  #checkout-progress .progress-bar .step .step-label {
    top: calc(100% + 15px);
  }
}

#checkout-progress .progress-bar .step span {
  color: #b07f35;
  transition: 0.3s ease;
  display: block;
  -webkit-transform: translate3d(0, 0, 0) scale(1) perspective(1000px);
  transform: translate3d(0, 0, 0) scale(1) perspective(1000px);
  font-size: 16px;
}

#checkout-progress .progress-bar .step .fa-check {
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  -webkit-transform: translate3d(-50%, -50%, 0) scale(0) perspective(1000px);
  transform: translate3d(-50%, -50%, 0) scale(0) perspective(1000px);
}

#checkout-progress .progress-bar .step.active span,
#checkout-progress .progress-bar .step.active .step-label {
  color: #b07f35;
  font-size: 16px;
}

#checkout-progress .progress-bar .step.valid .fa-check {
  -webkit-transform: translate3d(-50%, -50%, 0) scale(1) perspective(1000px);
  transform: translate3d(-50%, -50%, 0) scale(1) perspective(1000px);
}

#checkout-progress .progress-bar .step.valid span {
  color: #fff !important;
  background-color: #b07f35;
}

#checkout-progress .progress-bar .step.valid .step-label {
  color: #2c3e50 !important;
}

#checkout-progress .progress-bar .step:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) perspective(1000px);
  transform: translate(-50%, -50%) perspective(1000px);
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #ccc;
  transition: 0.3s ease;
}

#checkout-progress .progress-bar .step.active:after {
  border-color: #b07f35;
  background-color: #b07f35;
}

#checkout-progress .progress-bar .step.valid:after {
  background-color: #b07f35;
  border-color: #b07f35;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  margin: 100px auto 10rem;
}

.button-container .btn:hover {
  background-color: #fff;
  color: #4e4e4e;
}

.button-container .btn {
  position: relative;
  display: inline-block;
  background-color: #4e4e4e;
  color: #fff;
  padding: 20px 20rem;
  text-transform: uppercase;
  font-weight: 900;
  border: 3px solid #4e4e4e;
  transition: 0.3s ease;
  cursor: pointer;
  text-align: center;
}

.step.active span {
  color: #fff !important;
}

.step.step-6::after {
  display: none !important;
}

/* page no.2  */

.cmn_hdr h2 {
  font-size: 3rem;
}

.bg_grey {
  background-color: #f8f8f8;
  padding: 5rem;
  margin-bottom: 15px;
}

.switch2 {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch2 input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 5px;
  background-color: red;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

input:focus + .slider {
  box-shadow: 0 0 1px #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

input:checked + .slider:before {
  transform: translateX(30px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.hdng_h3 {
  font-size: 2.4rem;
  color: #141414;
  font-family: "proxima_novasemibold";
  font-weight: 500;
}

span.slider {
  width: 60px;
  height: 30px;
}

.switch_box {
  width: 125px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.switch_box h4 {
  font-family: "proxima_novasemibold";
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
}

.form-group {
  display: flex;
  gap: 45px;
  align-items: center;
  margin: 19px 0;
  width: 60%;
  justify-content: space-between;
}

.iform-group {
  display: flex;
  gap: 45px;
  align-items: center;
  margin: 19px 0;
}

.fromgroup_hdng {
  font-family: "proxima_novasemibold";
  font-weight: 500;
  font-size: 19px;
  color: #8c8c8c;
  width: 75%;
}

.form-input-group input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.form-input-group label {
  font-family: "proxima_novasemibold";
  font-weight: 500;
  font-size: 19px;
  color: #8c8c8c;
  display: block;
}

.form-input-group input,
.form-input-group select,
.form-input-group select {
  width: 100%;
  margin-top: 1rem;
  height: 5rem;
  text-transform: capitalize;
  outline-color: #c8c8c8;
}

.form-input-group {
  width: 48%;
}

.parent_forminput_group {
  display: flex;
  align-items: center;
  gap: 3rem;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.iform-group .fromgroup_hdng {
  width: auto;
}

.form-input-group input[type="radio"] {
  width: 20px;
  height: 20px;
  margin-top: 0;
  accent-color: #848484;
}

.form-input-group input[type="radio"]:checked + label {
  color: #000;
}

.form_radio_group {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.frm_radio_prnt {
  display: flex;
  gap: 5rem;
  margin-top: 20px;
}

input[type="number"],
input[type="text"] {
  border-width: 1px;
}

/* 
.radio-button {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: center;
  margin: 10px;
  position: relative;
}

.radio-button input[type="radio"] {
  position: absolute;
  opacity: 0;
  z-index: 1;
}

.radio {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #fff;
  transition: all 0.3s ease-in-out;
  transform: translateY(3px);
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.radio::before {
  position: absolute;
  content: "";
  width: 14px;
  height: 14px;
  top: 1px;
  left: 1px;
  border-radius: 50%;
  background-color: #848484;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.radio-button input[type="radio"]:checked + .radio {
  border-color: #fff;
  transform: translateY(3px);
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.radio-button input[type="radio"]:checked + .radio::before {
  opacity: 1;
} */
.other_ope_dtls.bg_grey .form-group {
  gap: 1rem !important;
  width: 81%;
}

.other_ope_dtls.bg_grey .form-group .fromgroup_hdng {
  width: 80%;
}

.additional_location .form-input-group .iform-group {
  margin-top: 25px;
  margin-bottom: 0;
}

.Atactivity .iform-group {
  width: 100%;
}

.logo_area {
  width: 26.5rem;
}

#checkout-progress {
  margin-bottom: 5rem;
}

/* for log in page 20.08.23 ankur */
header.sign_up_header {
  padding: 5rem 0;
}

.bnr_sec h1 {
  font-size: 6rem;
  font-family: "proxima_novaregular";
  line-height: normal;
  padding-bottom: 5rem;
}

.form_sec {
  max-width: 53rem;
  padding: 4rem 4rem 2rem 4rem;
  background: #f8f8f8;
  position: absolute;
  top: 5rem;
  right: 16%;
  width: 100%;
}

.form_sec:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1rem;
  background: linear-gradient(to right, #b38337, #e6c354, #b38337);
  top: 0;
  left: 0;
}

.frm_hdng h3 {
  font-size: 3.2rem;
  padding-bottom: 4.5rem;
}

.sign_up_flx_bx {
  display: flex;
  gap: 2rem;
  padding-bottom: 2rem;
}

.sign_up_flx_bx .sgn_up_flx_bx_inr {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  cursor: pointer;
}

.sign_up_flx_bx .sgn_up_flx_bx_inr span {
  width: 2.2rem;
  aspect-ratio: 1;
  background-color: #fff;
  display: block;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  border-radius: 50%;
  border: 4px solid #fff;
}

.sign_up_flx_bx .sgn_up_flx_bx_inr p {
  color: #8c8c8c;
  font-size: 1.8rem;
  font-family: "proxima_novasemibold";
  transition: 0.5s all;
}

.sign_up_flx_bx .sgn_up_flx_bx_inr.active span {
  background-color: #848484;
  transition: 0.5s all;
}

.sign_up_flx_bx .sgn_up_flx_bx_inr.active p {
  color: #000;
}

/* Sign Up Form Content */
.sgn_up_cntnt .inpt_bx {
  height: 6.5rem;
  margin: 2rem 0;
  background-color: #fff;
  border: 1px solid #c8c8c8;
  position: relative;
}

.sgn_up_cntnt .inpt_bx input {
  width: 100%;
  height: 100%;
  font-size: 1.9rem;
  color: #000;
  padding: 0 3rem;
  border: 0;
  font-family: "proxima_novasemibold";
}

.sgn_up_cntnt .inpt_bx .dropdown {
  height: 100%;
}

.sgn_up_cntnt .inpt_bx .dropdown button {
  width: 100%;
  height: 100%;
  font-size: 19px;
  border: 1px solid #c8c8c8;
  color: #8c8c8c;
  padding: 0 3rem;
  background-color: transparent;
  text-align: left;
}

.sgn_up_cntnt .inpt_bx input::placeholder {
  color: #8c8c8c !important;
}

.sgn_up_cntnt .inpt_bx select {
  width: 100%;
  height: 100%;
  background: url(../images/arrow_down_btn.png) no-repeat;
  background-position: 95% center;
  font-family: "proxima_novasemibold";
  appearance: none;
  font-size: 1.9rem;
  padding: 0 3rem;
  color: #8c8c8c;
  border: none;
  cursor: pointer;
}

.sgn_up_cntnt .inpt_bx input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
}

.upld_lgo_lbl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  padding: 0;
  padding-left: 3rem;
  font-weight: 400;
  width: 100%;
  background: url(../images/arw_pls_btn.png) no-repeat;
  background-position: 95% center;
  font-size: 1.9rem;
  color: #8c8c8c;
  font-family: "proxima_novasemibold";
}

.sgn_up_cntnt .inpt_bx input[type="submit"] {
  color: #fff;
  background-color: #4e4e4e;
  transition: 0.5s all;
  cursor: pointer;
}

.sgn_up_cntnt .inpt_bx input[type="submit"]:hover {
  background-color: #4e4e4ebb;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

#grdn_sgn_up_bnk {
  display: none;
}

header.sign_up_header .logo {
  width: 30.6rem;
  display: block;
}

/* for log in page 20.08.23 ankur */
/* for page 4 ayan dey 20.7.23  */

.check_form-group input[type="checkbox"]:checked ~ label {
  color: #141414;
}

.check_form-group label {
  font-family: "proxima_novasemibold";
  color: #8c8c8c;
}

label {
  user-select: none;
}

.check_checking {
  margin: 2rem 0;
  display: flex;
  gap: 10px;
}

.form-input-group.full {
  width: 100%;
}

.parent_forminput_group .form-group {
  width: 48%;
  justify-content: start;
  margin: 0;
}

.parent_forminput_group .form-group .fromgroup_hdng {
  width: auto;
}

/* for page 4 ayan dey 20.7.23  */
/* for page 1 ayon paul 20.7.23  */
.cmn_hdr h6 {
  color: #141414;
  font-size: 2.4rem;
  letter-spacing: 1px;
  line-height: 1.5;
  padding-bottom: 10px;
  font-family: "proxima_novasemibold";
}

.cmn_hdr p {
  font-size: 18px;
  color: #8c8c8c;
  line-height: 1.5;
  padding-bottom: 10px;
  font-family: "proxima_novasemibold";
}

.pg1-mn-otr label,
.pg3-mn-otr label {
  font-size: 18px;
  color: #8c8c8c;
  line-height: 1.5;
  padding-bottom: 10px;
  font-family: "proxima_novasemibold";
  display: block;
}

.cmn_hdr.wht p {
  color: #fff;
}

.pg1-prt-1-inrfld input[type="radio"] {
  width: 2.2rem;
  height: 2.2rem;
  accent-color: #848484;
}

.pg1-prt-1-inrfld {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  align-items: start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  gap: 10px;
}

.pg1-prt-1-txt {
  padding: 4rem;
  background: #f8f8f8;
}

input[name="pg-rd"]:checked + label {
  color: #141414;
}

.pg1-prt-2-txt {
  padding: 4rem;
  background: #f8f8f8;
}

.pg1-prt-2-inrfld {
  width: 49%;
}

.pg1-prt-2-inrfld.nd4 {
  width: 100%;
}

.pg1-prt-2-otrfld {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  row-gap: 2.7rem;
}

.pg1-prt-2-inrfld .form-control {
  height: 5rem;
  font-size: 18px;
  color: #8c8c8c;
  font-family: "proxima_novasemibold";
  border: 1.5px solid #c8c8c8;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  width: 100%;
  padding: 0 10px;
}

.pg1-prt-2-inrfld .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1.5px solid #b07f35;
  caret-color: #b07f35;
}

.pg1-prt-2-inrfld.nd2 {
  width: 100%;
}

.pg1-prt-2-prtfld {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: initial;
  -ms-flex-align: initial;
  align-items: initial;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  gap: 10px;
}

.pg1-prt-2-inrfld input[type="radio"] {
  width: 2.2rem;
  height: 2.2rem;
  accent-color: #848484;
}

.pg1-prt-2-prt-otrfld {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 4.5rem;
}

.pg1-prt-2-lwr-otrfld {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  align-items: self-start;
  gap: 2%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pg1-prt-2-lwr-inrfld {
  width: 49%;
}

.pg1-prt-2-lwr-inrfld:last-child {
  width: 15%;
  position: relative;
}

.pg1-prt-2-inrfld.nd3 {
  width: 100%;
}

.nd3-a {
  position: absolute;
  top: 85%;
  -webkit-transform: translateY(-85%);
  -ms-transform: translateY(-85%);
  transform: translateY(-85%);
  right: 2rem;
}

.nd3-a i {
  color: #8c8c8c;
  font-size: 3rem;
}

.nd3-a:hover i {
  color: #b07f35;
}

.pg1-prt-2-lwr-inrfld .form-control[value="10"] {
  color: #141414;
  padding-left: 2rem;
}

.toggle-bg {
  background: #fff;
  border-radius: 30px;
  display: inline-block;
  height: 2.7rem;
  position: relative;
  width: 5.7rem;
  -webkit-box-shadow: 0 0 20px #0001;
  box-shadow: 0 0 20px #0001;
}

.toggle-bg input {
  height: 3rem;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 5.5rem;
  z-index: 2;
}

.switch {
  background: #479837;
  border-radius: 30px;
  display: block;
  height: 2.3rem;
  left: 4px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: left 0.2s ease;
  -o-transition: left 0.2s ease;
  transition: left 0.2s ease;
  width: 2.3rem;
  z-index: 1;
}

.toggle-bg input ~ :checked ~ .switch {
  left: 3rem;
}

.toggle-bg input:checked {
  z-index: 0;
}

.pg1-prt-2-end-prtfld {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  gap: 10px;
}

.pg1-prt-2-end-prtfld span {
  color: #141414;
  font-size: 18px;
  font-family: "proxima_novasemibold";
}

.pg1-prt-2-end-inrfld {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  gap: 2.6rem;
}

.pg1-prt-3 {
  padding: 4rem;
  background: #f8f8f8;
}

.pg1-prt-3-end-inrfld {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  gap: 2.6rem;
}

.pg1-prt-3-txt .pg1-prt-3-otrfld:last-child {
  margin-top: 2.7rem;
}

.pg1-mn-inr .pg1-prt-1 {
  margin-bottom: 10px;
}

.pg1-mn-inr .pg1-prt-2 {
  margin-bottom: 10px;
}

.pg1-prt-3-end-inrfld label {
  padding-bottom: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* for page 1 ayon paul 20.7.23  */
/* for page 3 ayon paul 20.7.23  */
.pg3-mn-inr {
  padding: 4rem;
  background: #f8f8f8;
}

.pg3-mn-inrfld input[type="checkbox"] {
  width: 18px;
  height: 18px;
}

.pg3-mn-inrfld {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;

  align-items: start;
  margin-bottom: 5px;
}

input[name="pg-bx"]:checked + label {
  color: #141414;
}

.pg3-mn-inrfld label {
  line-height: 1.2;
}

/* for page 3 ayon paul 20.7.23  */
/* for page 5 ayan dey 20.07.23  */
.outr_finptgrp {
  margin-top: 3rem;
}

.outr_finptgrp .form-group {
  width: max-content;
}

.outr_finptgrp .form-group .fromgroup_hdng {
  width: max-content;
}

.pflex {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.pflex .c {
  width: 48%;
}

.com_dtails .pflex {
  align-items: start;
}
.wmcontent {
  width: max-content !important;
}
.w100 {
  width: 100% !important;
}

.form-input-group.w100 {
  margin: 2rem 0;
}

.fservc .check_form-group {
  margin-top: 3.5rem;
}

.fservc .check_checking {
  margin: 10px 0;
}

/* for page 5 ayan dey 20.07.23  */
input[type="radio"] {
  accent-color: #b07f35 !important;
}

input[type="text"],
input[type="number"],
.form-input-group textarea {
  padding-inline: 10px;
  transition: 0.5s all;
}

input:focus {
  border: 1.5px solid #b07f35;
  transition: 0.5s all;
  caret-color: #b07f35;
}

input[type="checkbox"] {
  accent-color: #b07f35;
}

/* Ankur 02.08.2023 */

.ttl_cntnt {
  display: flex;
}

.dash_board {
  width: 32.5rem;
  background-color: #000000;
  overflow: hidden;
  position: fixed;
  top: 5px;
  bottom: 0;
  left: 0;
}

.bnk_sctn {
  width: calc(100% - 32.5rem);
  margin-left: auto;
  background-color: #fdfdfd;
}

.dash_board .logo {
  padding: 15px 3rem;
}

.dash_board .logo a {
  max-width: 26.5rem;
}

.nav_bar {
  margin-top: 6.5rem;
}

.nav_bar ul li .nav-menu {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 2rem 3rem 2rem 4rem;
  margin: 1rem 0;
  border-left: 4px solid transparent;
  transition: 0.5s all;
  cursor: pointer;
}

.nav_bar ul li .nav-menu img {
  filter: invert(1) brightness(100);
  opacity: 0.8;
  transition: 0.5s all;
}

.nav_bar ul li .nav-menu p {
  font-size: 15px;
  text-transform: uppercase;
  color: #b3b3b3;
  font-family: "proxima_novasemibold";
  letter-spacing: 1px;
  transition: 0.5s all;
}

.nav_bar ul li .nav-menu.active {
  border-left: 4px solid #b07f35;
  box-shadow: 0px 0 20px 0px #b07f35;
}

.nav_bar ul li .nav-menu.active img {
  filter: none;
  opacity: 1;
}

.nav_bar ul li .nav-menu.active p {
  background: linear-gradient(to right, #ba8c4a, #e7c454, #ba8c4a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav_bar ul li {
  position: relative;
}

.drop_dwn_cntnt {
  margin-top: 15px;
  padding-left: 7rem;
}

.drpdwnLink {
  text-transform: uppercase;
  color: #b3b3b3;
  font-family: "proxima_novasemibold";
  letter-spacing: 1px;
  font-size: 15px;
  margin-bottom: 2rem;
  display: block;
  transition: 0.5s all;
}

.drpdwnLink.active {
  color: #fff;
}

.nav_list .drop_dwn_cntnt {
  /* display: none; */
}

body:after {
  position: fixed;
  content: "";
  width: 100%;
  height: 5px;
  background: linear-gradient(
    90deg,
    rgba(182, 135, 56, 1) 0%,
    rgba(231, 196, 84, 1) 52%,
    rgba(169, 119, 49, 1) 100%
  );
  top: 0;
  left: 0;
}

body:before {
  position: fixed;
  content: "";
  width: 100%;
  height: 5px;
  background: linear-gradient(
    90deg,
    rgba(182, 135, 56, 1) 0%,
    rgba(231, 196, 84, 1) 52%,
    rgba(169, 119, 49, 1) 100%
  );
  bottom: 0;
  left: 0;
}

.bank_content {
  padding-left: 10rem;
  padding-right: 20rem;
  padding-top: 5rem;
}

.rghtTp_hdr {
  padding: 2rem 0;
  padding-left: 10rem;
  padding-right: 3rem;
  background-color: #fff;
  box-shadow: 0px 0 20px 0px #0004;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.rghtTp_hdr .srch_br {
  position: relative;
  width: 52rem;
  height: 50px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
}

.rghtTp_hdr .srch_br > * {
  border: none;
  outline: 0;
  background-color: transparent;
  color: #000;
}

.rghtTp_hdr .srch_br .srchBtn {
  position: absolute;
  left: 20px;
  cursor: pointer;
}

.rghtTp_hdr .srch_br .srch_cntnt {
  padding-left: 50px;
  padding-right: 2rem;
  width: 100%;
}
/* ad  */
/* .rghtTP_RghtOtr {
  display: flex;
  align-items: center;
} */

.rghtTP_RghtOtr .ntfctn_bl {
  padding: 10px 0;
  padding-right: 2.5rem;
  border-right: 2px solid #dbdbdb;
}

.rghtTP_RghtOtr .ntfctn_bl a {
  position: relative;
  display: block;
}

.rghtTP_RghtOtr .ntfctn_bl a:after {
  position: absolute;
  content: "0";
  width: 18px;
  height: 18px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #a4732f;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 13px;
  top: -5px;
  right: -8px;
}

.rghtTP_RghtOtr .prflDtl {
  padding: 10px 0;
  padding-left: 2.5rem;
  display: flex;
  align-items: center;
  position: relative;
}

.prflDtl .img_otr {
  width: 5rem;
  height: 5rem;
  border: 5px solid #fff;
  outline: #a4732f 1px solid;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prflDtl .img_otr img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.prflDrpDwn {
  /* position: relative; */
  display: flex;
  align-items: center;
  margin-left: 2rem;
}

.prflDrpDwn .drop-down {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 99;
}

.prflDrpDwn .drop-down .selected a {
  display: block;
  text-decoration: none;
  font-size: 1.8rem;
  color: #000;
  font-family: "proxima_novasemibold";
  position: relative;
}

.prflDrpDwn .drop-down .selected a:after {
  position: absolute;
  content: "";
  background: url(../images/drop-down.png) no-repeat;
  width: 12px;
  height: 6px;
  background-size: cover;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.prflDrpDwn .drop-down .option {
  position: relative;
}

.prflDrpDwn .drop-down .options ul {
  background: #fff;
  list-style: none;
  padding: 0px 0px;
  position: absolute;
  right: 0px;
  top: 15px;
  width: auto;
  min-width: 170px;
  border: 1px solid #d7d7d7;
  cursor: pointer;
  /* display: none; */
}

.prflDrpDwn .drop-down .selected span.value,
.prflDrpDwn .drop-down .options span.value {
  display: none;
}

.prflDrpDwn .drop-down .options ul li a {
  padding: 5px 10px;
  display: block;
  color: #000;
  font-size: 1.8rem;
  transition: 0.5s ease;
}

.prflDrpDwn .drop-down .options ul li a:hover {
  background: #0005;
  /* color: #fff; */
  color: #b07f35;
}

.rspnsv_hdr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #000;
}

#toggle_bar a {
  display: block;
  padding: 5px 10px;
  background-color: #fff;
  box-shadow: #ba8b4a79 0px 0px 5px 2px;
  color: #ba8c4a;
  overflow: hidden;
  border-radius: 5px;
}

.rspnsv_hdr {
  display: none;
}

.rspnsv_hdr .logo a {
  max-width: 26.5rem;
  display: block;
}

/* Ayon Paul 03.08.2023 */

.pg1-prt-2-lwr-inrfld.bnk1a {
  width: 20%;
  position: relative;
}

.pg1-prt-2-lwr-inrfld.bnk1b {
  width: 49%;
}

.pg1-prt-2-lwr-inrfld.bnk1b .form-control {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* background: url(../images/bnk_arw_dwn.png) no-repeat; */
  background-size: 14px;
  background-position: 98% center;
}

.pg1-prt-1-inrfld.bnkpge input[type="radio"] {
  width: 1.7rem;
  height: 1.7rem;
}

.pg1-mn-otr .pg1-prt-1-inrfld.bnkpge label {
  width: calc(100% - 1.7rem) !important;
}

.form-group.bnk4 {
  width: 54%;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  gap: 45px;
}

.form-group.bnk4 .fromgroup_hdng {
  width: auto;
}

.bnk_add_grp label {
  font-size: 18px;
  color: #8c8c8c;
  line-height: 1.5;
  padding-bottom: 10px;
  font-family: "proxima_novasemibold";
  display: block;
}

.bnk_add_grp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.bnk_add_grp .pg1-prt-2-lwr-inrfld {
  width: 47%;

  display: block;
}

.bnk_add_grp .form-control {
  height: 5rem;
  font-size: 18px;
  color: #8c8c8c;
  font-family: "proxima_novasemibold";
  border: 1.5px solid #c8c8c8;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  width: 100%;
  padding: 0 10px;
}

.bnk_add_grp .nd3-a {
  position: absolute;
  top: 43px;
  right: 2rem;
  transform: translateY(0);
}

.bnk_add_grp p {
  font-family: "proxima_novasemibold";
  font-weight: 500;
  font-size: 19px;
  color: #8c8c8c;
  display: block;
  width: 100%;
  margin-bottom: 20px;
}

.bnk4_frm_grp {
  width: 43% !important;
}
/* 03.08.23 */
.hdng_h4 {
  font-size: 1.9rem;
  color: #141414;
  font-family: "proxima_novasemibold";
  font-weight: 500;
}
.form-group.w100 .bnk_add_grp {
  width: 50%;
}
.notification_btn {
  font-size: 15px;
  /* color: #ec715d; */
  padding: 10px 20px;
  /* display: inline-block; */
  /* background: #fff; */
  border: 2px solid #b07f35;
  /* position: relative; */
  text-transform: capitalize;
  transition: 0.5s all;
  /* z-index: 1; */
  border-radius: 30px;
  /* overflow: hidden; */
  cursor: pointer;
}

.save-button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  margin: 100px auto 10rem;
}
.save-button-container {
  position: relative;
  display: inline-block;
  background-color: #4e4e4e;
  color: #fff;
  padding: 20px 20rem;
  text-transform: uppercase;
  font-weight: 900;
  border: 3px solid #4e4e4e;
  transition: 0.3s ease;
  cursor: pointer;
  text-align: center;
}
.save-button-container .btn:hover {
  background-color: #fff;
  color: #4e4e4e;
}

.file_bx {
  height: 6.5rem;
  margin: 2rem 0;
  padding-top: 1rem;
  padding-left: 2rem;
  background-color: #fff;
  border: 1px solid #c8c8c8;
  position: relative;
}

.price_box {
  margin: 8px 0;
}
.price_box h5 {
  font-size: 18px;
  margin: 0 0 5px;
}
.price_box input {
  height: 25px;
  border: 1px solid #c7c7c7;
}

.tabs {
  display: flex;
  align-items: start;
  justify-content: space-between;
  /* margin: 0 0 25px; */
  margin-left: 1%;
  /* margin-right: 1%; */
}
.tab {
  background: #dedede;
  flex: 1;
  margin-right: 10px;
  padding: 10px 5px;
  text-align: center;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.active-tab {
  background: linear-gradient(to right, #ba8c4a, #e7c454, #ba8c4a);
  border: none;
  /* background: #000000; */
  color: #ffffff;
}

.addnew_btn {
  font-size: 15px;
  /* color: #ec715d; */
  padding: 10px 20px;
  /* display: inline-block; */
  /* background: #fff; */
  border: 2px solid #b07f35;
  /* position: relative; */
  text-transform: capitalize;
  transition: 0.5s all;
  /* z-index: 1; */
  border-radius: 30px;
  /* overflow: hidden; */
  cursor: pointer;
  margin-right: 2rem;
}

.profile-page-icons {
  height: 20px;
  width: 20px;
  border-style: none;
  cursor: pointer;
}
/* ayan dey 26.08.23  */

.switch2 input[type="checkbox"]:checked + .slider::before {
  background-color: #479837;
}
.slider:before {
  background-color: #f51212;
}
.pg3-mn-inrfld input[type="checkbox"] {
  margin-top: 2px;
}

.pg1-prt-1-inrfld.bnkpge input[type="checkbox"] {
  margin-top: 6px;
}
.calendar {
  position: relative;
  float: right;
}
.rdrCalendarWrapper.rdrDateRangeWrapper {
  position: absolute;
  right: 0;
  top: 35px;
}
.srange {
  font-size: 2rem !important;
  width: max-content;
  padding: 5px;
  /* background-color: #848484; */
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.react-tel-input.inpt_bx .form-control {
  margin-left: 30px !important;
}
.react-tel-input.inpt_bx .form-control::placeholder {
  font-size: 1.9rem;
  color: #000;
  border: 0;
  font-family: "proxima_novasemibold";
}

.sweet-warning {
  background-color: #b07f35;
  width: "17rem";
  height: "10rem";
}
.sweet-warning:not([disabled]):hover {
  background-color: #b07f35;
  /* width: '30rem'; */
}
.custom-content {
  width: 40%;
  height: 200px;
}
.sweet-warning-custom {
  background-color: #b07f35;
  /* width:17rem; */

  margin-right: 305px;
}
.sweet-warning-custom:not([disabled]):hover {
  background-color: #b07f35;
  /* width: '30rem'; */
}
.normal-swal {
  margin-left: 200px;
}
.controlthis {
  width: "10rem";
}

/* ayon paul change / 18.10.2023 */

.mainsection.bnk_sctn .profile_div input {
  width: 100% !important;
}
.mainsection.bnk_sctn .profile_div .col-md-3:first-child {
  display: none;
}
/* shreyasi(26.10.23) */
.pg1-mn-otr .pg1-prt-2-inrfld .form-control {
  width: 40%;
}
.form-input-group input {
  width: 40%;
}
.pg1__inr {
  display: flex;
  gap: 10px;
}
/* ayan dey 15.11.23  */

.header {
  position: inherit;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: auto;
  transition: all 0.35s ease-in-out;
}
.header .navigation_bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 2rem 1rem;
}
.header .hamburger {
  position: relative;
  display: none;
  cursor: pointer;
  border: none;
  outline: none;
  opacity: 0;
  visibility: hidden;
  background: none;
  transform: rotate(0deg);
  transition: 0.35s ease-in-out;
}

.header .menu-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}
.header .menu-item {
  transition: all 0.35s ease-in-out;
}
.header .menu-item:last-child {
  padding-right: 0;
}
.header .menu-item.active {
  outline: none;
}
header .menu-item a {
  position: relative;
}
header .menu-item a::after {
  position: absolute;
  content: "";
  border-bottom: 1px solid #be963e;
  width: 0;
  bottom: 0;
  left: 0;
  transition: 0.3s ease all;
}
header .menu-item a:hover::after {
  width: 100%;
}
.header .menu-link {
  font-size: 18px;
  font-weight: 400;
  line-height: inherit;
  color: #000;
  text-transform: capitalize;
  text-rendering: optimizeLegibility;
  transition: all 0.35s ease-in-out;
  font-family: "proxima_novaregular";
}
.header .menu-link:active,
.header .menu-link:hover {
  border: none;
  outline: none;
  color: #be963e;
}
body.landing::after,
body.landing::before {
  display: none;
}
body.landing .logo_outr {
  width: 26.5rem;
}
.landinghdr .hamburger-inner,
.landinghdr .hamburger-inner::before,
.landinghdr .hamburger-inner::after,
.landinghdr .hamburger.is-active .landinghdr .hamburger-inner,
.landinghdr .hamburger.is-active .landinghdr .hamburger-inner::before,
.landinghdr .hamburger.is-active .landinghdr .hamburger-inner::after {
  background-color: #000 !important;
}
/* signup section  */
.parent_signup {
  display: flex;
  height: 324px;
  position: relative;
}
.left_signup,
.rgt_signup {
  width: 50%;
  position: relative;
}

.left_signup_cntnt {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.left_signup_cntnt h3 {
  font-size: 4.5rem;
  color: #fff;
  font-weight: 600;
  font-family: "proxima_novasemibold";
}
.lscnt_btn {
  margin-top: 2.5rem;
}
.cmn_btn.gry {
  background: #4e4e4e;
  border-color: #4e4e4e;
}
.cmn_btn.gry:hover {
  color: #4e4e4e;
  border-color: #4e4e4e;
}
.rgt_signup .left_signup_cntnt {
  padding-left: 14.5rem;
}
.left_pbuild_img {
  width: 51.8rem;
  height: 46.3rem;
  position: relative;
  background-color: #ad7b33;
}

.left_pbuild_img:hover::after {
  top: 0;
  left: 0;
}

/* .left_signup {
  background-image: url(../images/left_signup.jpg);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
} */
.parent_build {
  display: flex;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 6.5rem;
}
.build {
  position: relative;
  background-color: #fff;
  z-index: 1;
}
.build::before {
  position: absolute;
  content: "";
  z-index: -1;
  background: url(../images/build_after.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  top: 0;
  width: 53rem;
  height: 62.3rem;
}
.lpp {
  font-size: 2rem;
  color: #141414;
  font-family: "proxima_novaregular";
}
.rgt_pbuild {
  margin-left: 10rem;
  width: 48%;
}
.rgt_pbuild .lpp {
  margin-top: 3rem;
}
.lst_rgt_pbuild {
  width: 29.5rem;
  position: absolute;
  right: 0;
  top: 0;
}
.hiwrks_box {
  padding: 4rem;
  border-bottom: 8px solid;
  border-image: linear-gradient(to right, #b68738, #e7c454, #af7f34) 1;
  background-color: #fff;
  width: 23%;
}
.hiwrks_box h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #141414;
  font-size: 2.5rem;
}
.hwibx_img {
  width: 6rem;
  margin-bottom: 4rem;
}
.hiwrks_box p {
  margin-top: 2rem;
  font-size: 2rem;
}
.howitworks {
  padding-top: 10rem;
  padding-bottom: 30rem;
  background-color: #f8f8f8;
  position: relative;
  z-index: 1;
}
.parent_hiwrks {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
}
.howitworks .cmn_hdr {
  text-align: center;
}
.hiwrks_box:hover {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.btmh2 {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  font-size: 11.9rem;
  font-family: "proxima_novabold";
  color: #f0f0f0;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 0;
  line-height: 0.7;
  z-index: -2;
}
footer {
  background-color: #4e4e4e;
}
.top_footer {
  padding: 6rem 0;
  border-bottom: 1px solid #fff;
}
.footer_logo {
  width: 26.5rem;
  margin: auto;
}
.footer_menu {
  margin-top: 4.5rem;
}
.footer_menu ul {
  display: flex;
  gap: 5rem;
  justify-content: center;
}
.footer_menu ul li a {
  color: #ffffff;
}
.footer_menu ul li a:hover {
  color: #e7c454;
}
.btm_footer {
  padding: 3rem 0;
  text-align: center;
}
.btm_footer p {
  color: #fff;
  font-size: 2rem;
}
/* ayon paul 15.11.23  */
.vdo-inr {
  margin-top: 6rem;
  position: relative;
  height: 48.8rem;
  width: 91rem;
  margin: auto;
}
.vdo-inr video {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border: 2rem solid transparent;
  /* -o-border-image: url(../images/video-border.png);
  border-image: url(../images/video-border.png); */
  border-image-slice: 18;
}
#play {
  color: #fff;
  font-size: 4rem;
  height: 9.5rem;
  width: 9.5rem;
  background: linear-gradient(to right, #b68738, #e7c454, #af7f34);
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;
  opacity: 1;
  pointer-events: all;
  -webkit-user-select: all;
  -moz-user-select: all;
  user-select: all;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#close {
  color: #af7f34;
  font-size: 4rem;
  height: 9.5rem;
  width: 9.5rem;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  pointer-events: all;
  -webkit-user-select: all;
  -moz-user-select: all;
  user-select: all;
  opacity: 0;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid #af7f34;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.vdo-inr.active #play {
  opacity: 0;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.vdo-inr.active #close {
  opacity: 1;
  pointer-events: all;
  -webkit-user-select: all;
  -moz-user-select: all;
  user-select: all;
}
.vdo-inr::after {
  position: absolute;
  content: "";
  background: url(../images/lft-wng.png) no-repeat;
  background-size: contain;
  width: 44.5rem;
  height: 51.7rem;
  left: -35%;
  bottom: 4%;
  z-index: -1;
}
.vdo-inr::before {
  position: absolute;
  content: "";
  background: url(../images/rght-wng.png) no-repeat;
  background-size: contain;
  width: 44.5rem;
  height: 51.7rem;
  right: -35%;
  bottom: 4%;
  z-index: -1;
}
.grd-clnt-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 4.5rem;
  padding-top: 3rem;
  width: 86%;
  margin: auto;
}
.grl-clnt-col {
  width: 19%;
  min-height: 18.5rem;
  border-image-width: 1px;
  border-image-source: linear-gradient(to right, #b68738, #e7c454, #af7f34);
  border-image-slice: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
.grd-clnt-tp {
  text-align: center;
}
.grl-clnt-inr img {
  filter: grayscale(1);
  width: 90%;
}
.grd-clnt-sec {
  position: relative;
  padding: 8rem 0 33.6rem;
}
.grd-clnt-sec::after {
  position: absolute;
  content: "";
  background: url(../images/clnt-btn-fl.png) no-repeat;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 28.6rem;
  margin: auto;
}
.gt-tch-frm-ttl {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  width: 100%;
}
.gt-tch-frm-lft {
  width: 50%;
}
.gt-tch-frm-rght {
  width: 50%;
}
.gt-tch-frm-ttl .form-control {
  display: block;
  padding: 2rem;
  border: 1px solid;
  border-image: linear-gradient(to right, #b68738, #e7c454, #af7f34) 1;
  font-size: 16px;
  color: #606060;
  font-family: "proxima_novaregular";
  width: 100%;
}
.gt-tch-frm-lft .form-control {
  margin-bottom: 2rem;
}
.gt-tch-frm-rght textarea.form-control {
  resize: none;
}
.gt-tch-otr-row {
  display: flex;
  align-items: center;
}
.gt-tch-otr-col {
  width: 50%;
}
.gt-tch-sec .gt-tch-sd-img .left_pbuild_img {
  margin-left: auto;
}
.gt-tch-sec .gt-tch-sd-img .left_pbuild_img img {
  height: 100%;
  width: 100%;
}
.gt-tch-mn-frm {
  margin-top: 2.5rem;
}
.gt-tch-sec {
  background-color: #f8f8f8;
}
.grd-vd-sec {
  position: relative;
  z-index: 99;
  margin-top: -20.7rem;
}
.grd-clnt-sec {
  padding: 5.5rem 0 33.6rem;
}
.gt-tch-sec {
  position: relative;
  z-index: 1;
  margin-top: -5.4rem;
  background: #f8f8f8;
  padding-bottom: 9rem;
}

.gt-tch-frm-rght {
  height: 22.5rem;
}
.gt-tch-frm-rght textarea.form-control {
  height: 100%;
}
.gt-tch-frm-ttl .form-control::placeholder {
  font-size: 16px;
  color: #606060;
  font-family: "proxima_novaregular";
  text-transform: initial;
}
.gt-tch-frm-ttl .form-control {
  text-transform: initial;
}
.gt-tch-frm-ttl input[type="email"].form-control {
  text-transform: lowercase;
}
/* ayan dey 16.11.23  */

.left_pbuild {
  position: relative;
}
.left_pbuild_under {
  position: absolute;
  top: 12px;
  left: -16px;
  width: 51.8rem;
  height: 46.3rem;
}
.gt-tch-otr-col .left_pbuild {
  margin-left: auto;
  width: max-content;
}
.grl-clnt-col img {
  filter: grayscale(1);
  width: 14rem;
}
/* ayan dey 22-11-23  */
.parent_top_cntnt {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  margin: 4rem;
}
.tptc_total_bx {
  width: 32%;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 2rem;
}
.tptc_total_bx:nth-child(1) {
  background-color: #a4732f;
}
.tptc_total_bx:nth-child(2) {
  background-color: #af7f34;
}
.tptc_total_bx:nth-child(3) {
  background-color: #b38337;
}
.tptc_total_bx:nth-child(4) {
  background-color: #be963e;
}
.tptc_total_bx h4 {
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 2.2rem;
  font-family: "proxima_novasemibold";
  margin-bottom: 1rem;
  min-height: 5.4rem;
}
.tptc_total_bx .balance {
  font-size: 2.5rem;
  color: #fff;
  font-weight: 500;
  font-family: "proxima_novasemibold";
  margin-bottom: 1rem;
}
.tptc_total_bx h6 {
  color: #fff;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 15px;
  font-family: "proxima_novasemibold";
}
.parent_btm-cntnt_outr {
  padding: 4rem;
}
.prnt_btm_cntnt {
  background-color: #fff;
  padding: 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.prnt_btm_cntnt .prbtc_outr {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 2.5rem;
  font-family: "proxima_novasemibold";
  text-align: center;
}
.prnt_btm_search {
  margin-top: 2rem;
}
.prnt_btm_search .search-container input[type="text"] {
  border: none;
  padding: 16px 10px;
  font-size: 17px;
  display: block;
  width: 85%;
  background-color: #eec379;
}
.prnt_btm_search .search-container {
  width: 33rem;
}
.prnt_btm_search .search-container form {
  display: flex;
}
.prnt_btm_search .search-container button {
  float: right;
  padding: 16px 10px;
  width: 15%;
  background: #ddd;
  font-size: 17px;
  border: none;
  cursor: pointer;
}
.prnt_btm_search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prnt_btm_table table {
  border-collapse: collapse;
  width: 100%;
}

.prnt_btm_table td,
.prnt_btm_table th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.prnt_btm_table tr:nth-child(even) {
  background-color: #dddddd;
}
.prnt_btm_table {
  margin-top: 3rem;
}
.prnt_btm_search .search-container input[type="text"]::placeholder {
  color: #fff;
  font-weight: 500;
  font-size: 1.8rem;
}
.accept_btn {
  padding: 1rem 2rem;
  border-radius: 50px;
  font-size: 15px;
  color: #fff;
  font-family: "proxima_novasemibold";
  background-color: green;
  display: block;
  width: max-content;
}
.accept_btn:hover,
.declined_btn:hover {
  transform: scale(1.05);
}
.declined_btn {
  padding: 1rem 2rem;
  border-radius: 50px;
  font-size: 15px;
  color: #fff;
  font-family: "proxima_novasemibold";
  background-color: red;
  display: block;
  width: max-content;
}
.eye_btn {
  color: #a4732f;
  font-size: 1.8rem;
  cursor: pointer;
  width: max-content;
  margin: auto;
  display: block;
}
.swal-footer {
  background: #fff;
  display: flex;
  justify-content: end;
}
.swal-button {
  font-size: 16px;
  border: none;
  outline: none;
  box-shadow: none;
}
.swal-butto:focus,
.swal-button--cancel:focus {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}
.bank_profile_from {
  width: 70%;
  /* margin-inline: auto; */
}
/* -- profile pic --  */
.profile-pic {
  color: transparent;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
}
.profile-pic input {
  display: none;
}
.profile-pic img {
  position: absolute;
  object-fit: cover;
  width: 16.5rem;
  height: 16.5rem;
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
  border-radius: 100px;
  z-index: 0;
}
.profile-pic .-label {
  cursor: pointer;
  width: 16.5rem;
  height: 16.5rem;
}
.profile-pic:hover .-label {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  color: #fafafa;
  transition: background-color 0.2s ease-in-out;
  border-radius: 100px;
  margin-bottom: 0;
}
.profile-pic span {
  display: inline-flex;
  padding: 0.2em;
  height: 2em;
  font-size: 1.8rem;
}

/* --- profile pic end ---  */
.bpp_upper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bnk_profile_page {
  margin: 4rem;
  width: 60%;
  margin-inline: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 2rem;
  background: #f8f8f8;
}
.bpp_upper .cmn_hdr h2 {
  font-weight: 700;
  font-family: "proxima_novabold";
}
.bank_profile_from {
  margin-top: 3rem;
}
/* bank from design start  */
.form__group {
  position: relative;
  padding: 20px 0 0;
  width: 100%;
  margin-bottom: 10px;
}
textarea.form__field {
  height: 30px;
  resize: none;
}
.form__field {
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 1px solid #9b9b9b;
  outline: 0;
  font-size: 1.8rem;
  background-color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 1.8rem;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1.8rem;
  color: #9b9b9b;
  pointer-events: none;
  transition: 0.3s ease all;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 1px;
  border-image: linear-gradient(to right, #b68738, #e7c256);
  border-image-slice: 1;
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 2rem;
  color: #121212;
  font-weight: 700;
}

/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}
/* bank from design end  */
.submit_btn.button-container {
  margin: 30px auto 2rem;
  justify-content: end;
}
.button-container .btn {
  padding: 12px 12rem;
}
.hdng_p {
  color: #141414;
  font-size: 2.4rem;
  letter-spacing: 1px;
  line-height: 1.5;
  padding-bottom: 10px;
  font-family: "proxima_novasemibold";
}
/* GBS Home page(15.2.24) */
/* common header */
.hm.cmn_hdr h2 {
  font-size: 5rem;
  line-height: 1;
  color: #141414;
  padding-bottom: 10px;
  font-family: "trajan_proregular";
  font-weight: 700;
}
.hm.cmn_hdr.wht h2 {
  color: #fff;
}
.hm.cmn_hdr p {
  font-size: 20px;
  color: #3d565b;
  line-height: 1.5;
  padding-bottom: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.hm.cmn_hdr.wht p {
  color: #fff;
}
.hm.cmn_hdr.clr p {
  color: #94693c;
}
.hm.cmn_hdr h3 {
  font-size: 4rem;
  line-height: 1;
  color: #141414;
  padding-bottom: 10px;
  font-family: "trajan_probold";
  font-weight: 600;
}
.hm.cmn_hdr h5 {
  color: #2b2b2b;
  font-size: 3rem;
  letter-spacing: 1px;
  line-height: 1.5;
  padding-bottom: 10px;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.hm.cmn_hdr h6 {
  color: #101010;
  font-size: 2.5rem;
  letter-spacing: 1px;
  line-height: 1.5;
  padding-bottom: 10px;
  text-transform: capitalize;
  font-family: "trajan_probold";
  font-weight: 600;
}
.hm.cmn_hdr.wht h6 {
  color: #fff;
}
.hm.cmn_btn {
  font-size: 20px;
  color: #000;
  padding: 15px 30px;
  display: inline-block;
  border: 2px solid #a47840;
  background: linear-gradient(to right, #a47840, #facc53, #a47840);
  position: relative;
  text-transform: uppercase;
  transition: 0.5s all;
  z-index: 1;
  font-weight: 600;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.hm.cmn_btn::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.7s;
}
.hm.cmn_btn:hover {
  color: #a47840;
  border: 2px solid #a47840;
  background-color: #fff;
}
.hm.cmn_btn:hover::after {
  width: 100%;
}
/* shreyasi */
.hm_bnr {
  position: relative;
}
.hm_bnr_txt {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.hm_hdr {
  position: absolute;
}
.hm_hdr .menu-link {
  color: #fff;
  font-family: "Poppins";
  text-transform: uppercase;
}
.hm_bnr_txt h1 {
  font-size: 8.5rem;
  font-family: "trajan_proregular";
  font-weight: 700;
  color: #fff;
}
.srch_icon {
  color: #fff;
}
.menu {
  display: flex;
  align-items: center;
  gap: 40px;
}

.hm_bnr_txt.hm.cmn_hdr.wht p {
  width: 75rem;
  margin: 10px 0 15px 0;
}
.hm_bnr_txt.hm.cmn_hdr h6 {
  background-color: #333235;
  display: inline-block;
  padding-bottom: 0;
  font-family: "Poppins";
  font-weight: 400;
  text-transform: uppercase;
  font-size: 20px;
}

.hm_fetr_dtl {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hm_fetr_part,
.hm_fetr_part_hdng {
  width: 32%;
}
.hm_fetr_part:first-child {
  text-transform: uppercase;
}
.hm_fetr_hdng.hm.cmn_hdr h3 {
  font-family: "trajan_proregular";
  font-weight: 700;
}
.hm_fetr_txt.hm.cmn_hdr h4 {
  font-size: 3.5rem;
  font-family: "trajan_proregular";
  font-weight: 700;
}
.hm_fetr_part {
  background-color: #fff;
  padding: 60px 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  height: 238px;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.hm_fetr_part:hover {
  background: url(../images/hm_icon_bg.png) no-repeat;
  background-size: 100% 100%;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.hm_fetr_txt.hm.cmn_hdr p,
.hm_fetr_txt.hm.cmn_hdr h4 {
  color: #101010;
}
.hm_features .hm_fetr_part .hm_ftr_icon img:first-child {
  display: block;
}
.hm_features .hm_fetr_part:hover .hm_ftr_icon img:first-child {
  display: none;
}
.hm_features .hm_fetr_part .hm_ftr_icon img:last-child {
  display: none;
}
.hm_features .hm_fetr_part:hover .hm_ftr_icon img:last-child {
  display: block;
}
.hm_efcncy .hm_fetr_part_hdng {
  width: 100%;
  margin: auto;
  text-align: center;
}
.hm_efcncy .hm_fetr_part_hdng h2 {
  font-family: "trajan_proregular";
  font-weight: 700;
  padding-bottom: 50px;
}
.hm_efcncy .hm_fetr_part {
  width: 48%;
  background: url(../images/efcncy_bg.png) no-repeat;
}
.hm_efcncy .hm_fetr_part:hover {
  background: url(../images/efcny_hvr_bg.png) no-repeat;
}
.hm_key_hdng {
  text-align: center;
}
.hm_key_hdng.cmn_hdr h2 {
  text-transform: uppercase;
  font-family: "trajan_proregular";
  font-weight: 700;
}
.hm_key_hdng.cmn_hdr h5 {
  color: #94693c;
  font-size: 2.2rem;
  font-family: "Poppins";
  font-weight: 400;
}
.hm_key {
  background-color: #f3f3f3;
}
.key_hm {
  margin-top: 30px;
}
.hm_key .hm_fetr_part:hover {
  background: #fff;
}
.hm_key .hm_fetr_part {
  align-items: end;
}
.hm_key .hm_fetr_txt.hm.cmn_hdr h6 {
  font-family: "trajan_proregular";
  line-height: 1.2;
  margin-top: 20px;
}
.hm_fetr_txt.hm.cmn_hdr h5 {
  background: linear-gradient(to right, #a47840, #facc53, #a47840);
  width: 8.6rem;
  height: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  padding-bottom: 0;
}
.hm_key {
  position: relative;
}
.hm_key::before {
  position: absolute;
  content: "";
  background: url(../images/key_flwr.png) no-repeat;
  background-size: 100% 100%;
  width: 32.2rem;
  height: 53rem;
  top: 0;
  left: 0;
  bottom: 0;
}
.hm_key::after {
  position: absolute;
  content: "";
  background: url(../images/key_flwr.png) no-repeat;
  transform: rotate(180deg);
  background-size: 100% 100%;
  width: 32.2rem;
  height: 53rem;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
.hm_key .hm_fetr_part {
  padding-bottom: 30px;
}
.hm_fetr_dtl.key_hm {
  position: relative;
  z-index: 9;
}
.hm_solution .hm_key_hdng.hm.cmn_hdr h5 {
  color: #ffd154;
}
.hm_sltn_dtl {
  margin-top: 30px;
}
.hm_sltn_text.hm.cmn_hdr.wht h5 {
  padding-bottom: 5px;
  text-transform: uppercase;
  color: #ffd154;
  font-family: "Poppins";
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1;
  margin: 15px 0 5px;
}
.hm_sltn_part {
  position: relative;
  width: 32%;
}
.hm_sltn_part::after {
  position: absolute;
  content: "";
  background-color: #fff;
  height: 100%;
  width: 2px;
  top: 0;
  bottom: 0;
  right: 16%;
}
.hm_sltn_part:last-child::after {
  background: none;
}
.hm_sltn_dtl {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* --------moumi----- */
footer {
  background-color: #101010;
  position: relative;
}
footer.cmn_pdding {
  padding-bottom: 20px;
}
.tp_ftr {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  border-bottom: 1px solid #fff;
  margin-top: 19rem;
  padding-bottom: 4rem;
}
.ftr_list {
  width: 23%;
}
.ftr_list:nth-child(2) {
  width: 16%;
}
.ftr_logo {
  width: 29.4rem;
  margin-top: 8rem;
}
.ftr_logo img {
  width: 100%;
  height: 100%;
}
.ftr_hdng h4 {
  color: #fff;
  font-family: "trajan_probold";
  font-size: 2.2rem;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  padding-bottom: 10px;
}
.ftr_lists p {
  font-size: 18px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  padding-bottom: 15px;
}
.ftr_lists ul li {
  margin-top: 10px;
  display: flex;
  align-items: center;
  color: #fff;
  gap: 15px;
}
.ftr_lists ul li a {
  color: #fff;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.ftr_lists ul li:hover,
.ftr_lists ul li:hover a,
.ftr_btm p a:hover {
  color: #edc456;
}
.ftr_icon {
  font-size: 20px;
}
.ftr_icon:hover {
  background: #000;
  color: #deb357;
}
.btm_ftr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}
.ftr_lft p a {
  color: #fff;
}
.ftr_lft p a:hover {
  color: #deb357;
}
.ftr_rgft ul {
  display: flex;
  align-items: center;
  gap: 15px;
  transition: 0.4 ease;
}
.ftr_rgft a {
  color: #fff;
  border: 2px solid #deb357;
  border-radius: 50%;
  padding: 10px;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: 0.4 ease;
}
.ftr_rgft li:hover a {
  color: #deb357;
  border: 2px solid #deb357;
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
}
.ftr_aftr {
  position: absolute;
  top: 0;
  left: 0;
  width: 37.3rem;
  height: 52.4rem;
}
.ftr_aftr_img img {
  width: 100%;
  height: 100%;
}
.get_started {
  position: relative;
  z-index: 1;
  margin-bottom: -22rem;
  overflow-x: hidden;
  background-image: url(../images/hm_get_started_bg.png);
  background-repeat: no-repeat;
  background-position: center;
}
.get_started_bg {
  padding: 5rem;
  text-align: center;
}
.get_started_bg.cmn_hdr p {
  color: #101010;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin: 15px 0;
}
.get_btn .cmn_btn {
  margin-top: 3rem;
  background-color: #fff;
  color: #000;
  border-radius: 17px;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.get_btn a {
  letter-spacing: 1.1px;
}
.get_started .cmn_btn:hover {
  background: linear-gradient(to right, #a47840, #facc53, #a47840);
  color: #a47840;
}
.mrktng_sec {
  background-color: #f3f3f3;
  position: relative;
}
.mrktng_innr {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.mrktng_lft {
  position: relative;
  width: 40%;
}
.mrkt__img {
  border-radius: 20px;
  overflow: hidden;
  width: 42.3rem;
  height: 62.7rem;
  position: relative;
  z-index: 1;
}
.mrkt__img img {
  width: 100%;
  height: 100%;
}
.mrktng_lft::after {
  position: absolute;
  width: 42.3rem;
  height: 68.7rem;
  content: "";
  border: 6px solid #facc53;
  border-radius: 20px;
  left: 46px;
  top: -31px;
}
.mrktng_rght {
  width: 60%;
  margin-top: 14rem;
}
.mrkt_div {
  display: flex;
  align-items: center;
  gap: 13px;
  background: #fff;
  width: 67%;
  padding: 8px;
  border-radius: 10px;
  margin-top: 16px;
}
.mrkt_icn {
  width: 8.5rem;
  height: 7rem;
  background: linear-gradient(to right, #a47840, #facc53, #a47840);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
.mrkt_txt.hm.cmn_hdr p {
  color: #101010;
  font-family: "Poppins", sans-serif;
}
.mrkt_txt.hm.cmn_hdr p span {
  font-weight: 500;
}
.strmln_hdr h2,
.strmln_hdr p {
  text-align: center;
}
.strmln {
  position: relative;
}
.strmln_innr {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
}
.strml_div {
  display: flex;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 24%;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  min-height: 200px;
}
.numb {
  width: 6.3rem;
  height: 6rem;
  background: linear-gradient(to right, #a47840, #facc53, #a47840);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.numb.hm.cmn_hdr h5 {
  padding-bottom: 0;
}
.strmln_lft p {
  font-size: 16px;
  padding: 26px 0 15px 0;
}
.strmln_rght img {
  width: 100%;
}
.mrktng_sec {
  position: relative;
  z-index: 1;
}
.mrkt_icn {
  padding: 15px;
}
.side_font h4 {
  font-weight: 700;
  font-size: 4rem;
  text-transform: uppercase;
  text-align: center;
  line-height: 0.7;
  position: absolute;
  right: 190px;
  transform-origin: left;
  writing-mode: vertical-rl;
  opacity: 0.1;
  z-index: -1;
  top: 86px;
  color: #ccc;
}
.strml_div.stml_arw::after {
  position: absolute;
  content: "";
  background-image: url(../images/str_img_dwn.png);
  background-repeat: no-repeat;
  width: 29rem;
  height: 13rem;
  left: -46%;
  bottom: 90px;
}
/* shreyasi */
.hm_efcncy .hm_fetr_part .hm_ftr_icon img:first-child {
  display: block;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.hm_efcncy .hm_fetr_part .hm_ftr_icon img:last-child {
  display: none;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.hm_efcncy .hm_fetr_part:hover .hm_ftr_icon img:first-child {
  display: none;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.hm_efcncy .hm_fetr_part:hover .hm_ftr_icon img:last-child {
  display: block;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.hm_efcncy,
.hm_features {
  background-color: #fff;
}
/* shreyasi(streamline process) */
.strmln {
  background-color: #101010;
  position: relative;
  border-bottom: 2px solid #808080;
}
.strmln::after {
  position: absolute;
  content: "";
  background: url(../images//aftr.png) no-repeat;
  background-size: 100% 100%;
  width: 300px;
  height: 400px;
  bottom: 0;
  right: 0;
  z-index: 0;
}
.strmln_otr {
  background-color: #fff;
  width: 84.5%;
  position: relative;
  z-index: 9;
}
.strmln_innr {
  margin-top: 50px;
  padding: 0 10%;
  position: relative;
}
.strml_div:last-child {
  position: absolute;
  right: -112px;
  bottom: 0;
  background-color: #fff;
  width: 17%;
}
.strml_div:nth-child(4) {
  margin: auto;
}
.strml_div:nth-child(5) {
  margin-right: 21.5rem;
}
.strml_div:last-child::before {
  position: absolute;
  content: "";
  background: url(../images/strmln_6_aftr.png) no-repeat;
  background-size: 100% 100%;
  right: -22px;
  top: -12px;
  width: 90px;
  height: 223px;
  z-index: -1;
  left: auto;
  bottom: auto;
}
.strmln_rght {
  width: 7rem;
}
/* 16.2.24 */
.strml_div.stml_arw:nth-child(2)::after {
  background: none;
}
.strml_div.stml_arw:first-child::after {
  left: 118%;
  bottom: -41px;
  background-image: url(../images/fst_arw.png);
}
.strml_div.stml_arw:nth-child(3)::after {
  left: -40%;
  bottom: -45px;
  background-image: url(../images/fst_arw.png);
}
.srch_icon {
  cursor: pointer;
}
.ftr_logo.logo_outr {
  position: relative;
  z-index: 9;
}
