.modal {
  border-radius: "10px";
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 50%;
  background-color: #ffffff;
  padding: 10px;
}
.content-passwordreset {
  width: 30%;
  background-color: #ffffff;
  padding: 10px;
}
.footer {
  margin-top: 10px;
}

.inpt_bx input[type="text"] {
  padding: 0 3rem;
  border: 1px solid #8c8c8c;
}

.pg3-mn-inrfld label {
  color: #8c8c8c;
  font-size: 18px;
}

.content {
  padding: 25px 30px;
}

.pg3-mn-otrfld {
  padding-bottom: 12px;
}

.footer {
  padding-top: 10px;
}

.content h4 {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  padding-bottom: 20px;
}
.content-passwordreset h4 {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  padding-bottom: 20px;
}
.notification_btn {
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: #4e4e4e;
  color: #fff;
  border-radius: 0;
  border: 1px solid transparent;
}

.notification_btn:hover {
  background-color: #fff;
  color: #4e4e4e;
  border: 1px solid #4e4e4e;
}

.content .business_box {
  background-color: #f8f8f8;
  padding: 30px;
}
