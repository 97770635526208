@media (max-width: 1920px) {
}

@media (max-width: 1680px) {
  .container {
    max-width: 1450px;
  }

  html {
    font-size: 53%;
  }

  .pg1-mn-otr label,
  .pg3-mn-otr label,
  .pg1-prt-2-inrfld .form-control,
  .pg1-prt-2-end-prtfld span,
  .cmn_hdr p {
    font-size: 17px;
  }

  .pg3-mn-inrfld input[type="checkbox"] {
    width: 17px;
    height: 17px;
  }

  /* changes */
  .pg1-prt-2-end-prtfld span:last-of-type {
    padding-left: 10px;
  }

  /* ayon paul start /02.08.2023 */

  .pg1-prt-2-lwr-inrfld.bnk1a {
    width: 20%;
  }

  .bnk_add_grp .pg1-prt-2-lwr-inrfld {
    width: 47%;
  }

  .form-group.bnk4 {
    width: 60%;
  }

  .bnk4_frm_grp {
    width: 48% !important;
    gap: 0;
  }

  .nav_bar ul li .nav-menu p,
  .drpdwnLink {
    font-size: 14px;
  }

  .nav_bar ul li .nav-menu {
    padding: 2rem 2rem 2rem 3rem;
  }

  .nav_bar {
    margin-top: 4rem;
  }

  /* ayon paul change / 18.10.2023 */

  .pg3-mn-inrfld label {
    font-size: 17px !important;
  }
  /* moumi 15.2.24 */
  /* .mrkt_div {
    width: 72%;
} */
  .hm.cmn_hdr p {
    font-size: 18px;
  }
  .mrktng_lft::after {
    height: 69.7rem;
  }
  .ftr_logo {
    width: 33.4rem;
  }
  .hm_key .hm_fetr_part {
    padding-bottom: 47px;
  }
  .hm_fetr_txt.hm.cmn_hdr p,
  .hm_fetr_txt.hm.cmn_hdr h4 {
    padding-top: 10px;
  }
  .ftr_lists ul li a,
  .ftr_lists p {
    font-size: 17px;
  }
  .ftr_rgft a {
    padding: 17px;
  }
  .hm_ftr_icon {
    width: 11.4rem;
  }
  .hm_ftr_icon img {
    width: 100%;
  }
  .strml_div:last-child::before {
    width: 114px;
  }
  .strml_div.stml_arw::after {
    height: 15rem;
  }
  .strml_div:last-child::before {
    height: 233px;
  }
}

/* ==================<<<<<>>>>> ================== */

@media (max-width: 1440px) {
  .container {
    max-width: 1340px;
  }

  html {
    font-size: 47%;
  }

  .fromgroup_hdng,
  .switch_box h4,
  .form-input-group label,
  input[type="number"],
  input[type="text"],
  .button-container .btn,
  .check_form-group label {
    font-size: 15px;
  }

  .frm_radio_prnt {
    gap: 4rem;
  }

  span.slider {
    width: 55px;
    height: 25px;
  }

  .slider::before {
    height: 15px;
    width: 15px;
  }

  .switch_box {
    width: 130px;
  }

  .form-input-group input[type="radio"] {
    width: 16px;
    height: 16px;
  }

  /* ayon paul 20.07.23  */
  .pg1-mn-otr label,
  .pg3-mn-otr label {
    font-size: 16px;
    line-height: 1.3;
  }

  .pg1-prt-2-inrfld .form-control {
    font-size: 16px;
  }

  .pg1-prt-2-end-prtfld span {
    font-size: 16px;
  }

  .pg1-mn-inr .pg1-prt-1 {
    margin-bottom: 7px;
  }

  .pg1-mn-inr .pg1-prt-2 {
    margin-bottom: 7px;
  }

  .pg3-mn-inrfld input[type="checkbox"] {
    width: 16px;
    height: 16px;
  }

  .cmn_hdr p {
    font-size: 16px;
  }

  /* ayon paul start /02.08.2023 */

  .pg1-prt-2-lwr-inrfld.bnk1a {
    width: 22%;
  }

  .pg1-prt-2-lwr-inrfld.bnk1b .form-control {
    background-size: 12px;
  }

  .pg1-prt-1-inrfld.bnkpge input[type="radio"] {
    width: 1.8rem;
    height: 1.8rem;
  }

  .bnk_add_grp .pg1-prt-2-lwr-inrfld {
    width: 47%;
  }

  .form-group.bnk4 {
    width: 62%;
  }

  .bnk_add_grp p {
    font-size: 16px;
  }

  .bnk_add_grp label {
    font-size: 16px;
  }

  .bnk_add_grp .form-control {
    font-size: 16px;
  }

  .nav_bar ul li .nav-menu p,
  .drpdwnLink {
    font-size: 13px;
  }

  .nav_bar ul li .nav-menu {
    padding: 2rem 2rem 2rem 2rem;
  }

  .button-container .btn {
    padding: 10px 10rem;
  }

  /* ayon paul change / 18.10.2023 */

  .pg3-mn-inrfld label {
    font-size: 16px !important;
  }

  .mainsection.bnk_sctn td,
  .mainsection.bnk_sctn th {
    font-size: 16px;
  }

  .mainsection.bnk_sctn .profile_div .col-md-5 {
    width: 50%;
  }

  .mainsection.bnk_sctn .profile_div .col-md-5 .button-container {
    justify-content: flex-start;
  }

  .mainsection.bnk_sctn .profile_div .col-md-5 .labels {
    font-size: 16px;
  }

  .mainsection.bnk_sctn .profile_div .col-md-5 .form-control {
    font-size: 15px;
  }

  .mainsection.bnk_sctn .profile_div .col-md-5 .prfl-hdng {
    font-size: 20px;
  }
  /* ayan dey 15.12.23  */
  .bank_profile_from {
    width: 80%;
  }
  /* moumi 15.2.24 */
  .hm_bnr_txt h1 {
    padding-top: 12px;
  }
  /* .hm_fetr_part {
    height: 220px;
} */
  .mrktng_innr {
    gap: 0;
  }
  .get_btn .cmn_btn {
    font-size: 18px;
  }
  .ftr_logo {
    width: 36.4rem;
  }
  .side_font h4 {
    right: 85px;
  }
  .mrktng_lft::after {
    height: 70.7rem;
  }
  .ftr_hdng h4 {
    font-size: 20px;
  }
  .ftr_lists ul li a,
  .ftr_lists p {
    font-size: 16px;
  }
  .strml_div {
    height: 181px;
  }
  .strml_div:last-child::before {
    height: 207px;
  }
  .strmln_rght {
    width: 10rem;
  }
  .strml_div:last-child::before {
    height: 224px;
  }
  .hm_key::before,
  .hm_key::after {
    height: 62.5rem;
  }
  .hm_bnr_txt h1 {
    font-size: 6.5rem;
  }
}

/* ==================<<<<<>>>>> ================== */

@media (max-width: 1366px) {
  .container {
    max-width: 1240px;
  }

  /* ayon paul start /02.08.2023 */

  .pg1-prt-2-lwr-inrfld.bnk1a {
    width: 19%;
  }

  .pg1-prt-2-lwr-inrfld.bnk1b .form-control {
    background-size: 10px;
  }

  .bnk4_frm_grp {
    width: 54% !important;
  }

  .form-group.bnk4 {
    width: 66%;
  }

  .pg1-prt-2-lwr-inrfld.bnk1a {
    width: 47%;
  }

  .bank_content {
    padding-right: 8rem;
  }
  /* ayan dey 16.11.23  */
  .btmh2 {
    font-size: 10.9rem;
  }
  .gt-tch-frm-rght {
    height: 24.5rem;
  }
  /* ayan dey 22.1.23  */
  .prnt_btm_table table {
    width: 1300px;
  }
  .prnt_btm_table {
    overflow: auto;
  }
  .prnt_btm_search .search-container input[type="text"],
  .prnt_btm_search .search-container button {
    padding: 1rem;
  }
  /* ayan dey 14.12.23  */
  .mainsection.bnk_sctn th {
    min-width: 100px;
  }
  .outr_table table {
    width: 100%;
  }
  .outr_table {
    overflow: auto;
    scrollbar-width: 1px; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }
  .outr_table table th {
    min-width: 120px;
    padding: 5px 1px;
    font-size: 12px;
    text-align: center;
  }
  .outr_table .switch_box {
    width: auto;
  }
  /* moumi 15.2.24 */
  .header .menu-inner {
    gap: 3rem;
  }
  .hm.cmn_hdr p {
    font-size: 17px;
  }
  .menu {
    gap: 25px;
  }
  .ftr_list {
    width: 24%;
  }
  .mrkt_div {
    width: 71%;
  }
  .header .menu-link {
    font-size: 17px;
  }
  .strml_div {
    min-height: 208px;
  }
  .strmln::after {
    width: 223px;
    height: 526px;
  }
  .get_started {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .strml_div:last-child::before {
    height: 231px;
  }
  .ftr_lists ul li a, .ftr_lists p {
    font-size: 14px;
}
}

/* ==================<<<<<>>>>> ================== */

@media (max-width: 1280px) {
  .container {
    max-width: 1140px;
  }

  html {
    font-size: 42%;
  }

  /* ayon paul 20.7.23 */
  .pg1-mn-otr label,
  .pg3-mn-otr label {
    font-size: 15px;
  }

  .pg1-prt-2-inrfld .form-control {
    font-size: 15px;
  }

  .pg1-prt-2-end-prtfld span {
    font-size: 15px;
  }

  .pg1-mn-inr .pg1-prt-1 {
    margin-bottom: 6px;
  }

  .pg1-mn-inr .pg1-prt-2 {
    margin-bottom: 6px;
  }

  .pg1-prt-2-end-prtfld {
    gap: 8px;
  }

  .pg3-mn-inrfld input[type="checkbox"] {
    width: 15px;
    height: 15px;
  }

  .cmn_hdr p {
    font-size: 15px;
  }

  /* changes */

  .pg1-prt-2-end-prtfld span:last-of-type {
    padding-left: 14px;
  }

  /* ayon paul start / 02.08.2023 */

  .pg1-prt-1-inrfld.bnkpge input[type="radio"] {
    width: 1.9rem;
    height: 1.9rem;
  }

  .bnk4_frm_grp {
    width: 55% !important;
  }

  .bnk_add_grp p {
    margin-bottom: 15px;
  }

  .form-group.bnk4 {
    width: 70%;
  }

  .nav_bar ul li .nav-menu {
    padding: 2rem 1rem;
  }

  .nav_bar ul li .nav-menu p,
  .drpdwnLink {
    font-size: 12px;
  }

  .bank_content label {
    font-size: 15px;
  }

  .bank_content .fromgroup_hdng,
  .bank_content .switch_box h4 {
    font-size: 15px;
  }

  .bnk_add_grp p {
    font-size: 15px;
  }

  .bnk_add_grp .form-control {
    font-size: 15px;
  }

  /* ayan dey 03.08.23  */
  .bank_content {
    padding-right: 9rem;
  }

  .bnk_add_grp .nd3-a {
    top: 40px;
  }

  /* ayon paul change / 18.10.2023 */

  .pg3-mn-inrfld label {
    font-size: 15px !important;
  }

  .mainsection.bnk_sctn td,
  .mainsection.bnk_sctn th {
    font-size: 15px;
  }

  .mainsection.bnk_sctn .profile_div .col-md-5 .labels {
    font-size: 15px;
  }

  .mainsection.bnk_sctn .profile_div .col-md-5 .form-control {
    font-size: 14px;
  }
  /* ayan dey 16.11.23  */
  .gt-tch-frm-rght {
    height: 25.5rem;
  }
  .cmn_btn,
  .header .menu-link,
  .footer_menu ul li a {
    font-size: 16px;
  }
  .cmn_btn {
    padding: 10px 20px;
  }
  /* moumi 15.2.24 */
  .hm_fetr_part {
    background-color: #fff;
    padding: 40px 20px;
    height: 215px;
  }
  .hm_fetr_part {
    background-color: #fff;
    padding: 40px 20px;
    height: 215px;
  }
  .hm_bnr_txt.hm.cmn_hdr h6 {
    font-size: 18px;
  }
  .hm_bnr_txt h1 {
    padding-top: 0;
  }
  .hm_bnr_txt.hm.cmn_hdr.wht p {
    margin: 5px 0 10px 0;
  }
  .hm.cmn_btn {
    font-size: 18px;
    padding: 13px 24px;
  }
  .hm_ftr_icon {
    width: 10.4rem;
  }
  .hm_key .hm_fetr_part {
    padding: 0 20px;
    align-items: center;
  }
  .ftr_hdng h4 {
    font-size: 17px;
  }
  .ftr_lists ul li a,
  .ftr_lists p {
    font-size: 14px;
  }
  .get_btn .cmn_btn {
    margin-top: 0;
  }
  .mrktng_lft::after {
    height: 68.7rem;
    left: 29px;
    top: -19px;
  }
  .mrkt_icn {
    width: 8.5rem;
    height: 8rem;
  }
  .side_font h4 {
    opacity: 0.4;
    top: 69px;
  }
  .hm.cmn_hdr p {
    font-size: 16px;
  }
  .hm_efcncy .hm_fetr_part_hdng h2 {
    padding-bottom: 30px;
  }
  .strmln_innr {
    margin-top: 30px;
  }
  .hm_bnr_txt h1 {
    font-size: 6.5rem;
  }
  .hm_sltn_icon {
    width: 60px;
  }
  .strmln::after {
    width: 187px;
    height: 565px;
  }
  .strml_div.stml_arw::after {
    height: 18rem;
  }
  .hm_key::before,
  .hm_key::after {
    height: 63.5rem;
  }
}

/* ==================<<<<<>>>>> ================== */

@media (max-width: 1199px) {
  .container {
    max-width: 100%;
  }

  /* ayon paul 20.7.23 */
  .pg1-mn-otr label,
  .pg3-mn-otr label {
    font-size: 14px;
  }

  .pg1-prt-2-inrfld .form-control {
    font-size: 14px;
  }

  .pg1-prt-2-end-prtfld span {
    font-size: 14px;
  }

  .pg1-mn-inr .pg1-prt-1 {
    margin-bottom: 5px;
  }

  .pg1-mn-inr .pg1-prt-2 {
    margin-bottom: 5px;
  }

  .pg1-prt-2-end-prtfld {
    gap: 6px;
  }

  .pg3-mn-inrfld input[type="checkbox"] {
    width: 14px;
    height: 14px;
  }

  .cmn_hdr p {
    font-size: 14px;
  }

  /* ayon paul start / 03.08.2023 */

  .form-group.bnk4 {
    width: 60%;
  }

  .form-group.bnk4 {
    width: 77%;
  }

  .bnk_add_grp .pg1-prt-2-lwr-inrfld {
    gap: 10px;
  }

  .bnk4_frm_grp {
    width: 56% !important;
  }

  .bank_content label {
    font-size: 14px;
  }

  .bank_content .fromgroup_hdng,
  .bank_content .switch_box h4 {
    font-size: 14px;
  }

  .bnk_add_grp p {
    font-size: 14px;
  }

  .bnk_add_grp .form-control {
    font-size: 14px;
  }

  /* ayon paul change / 18.10.2023 */

  .pg3-mn-inrfld label {
    font-size: 14px !important;
  }

  .mainsection.bnk_sctn td,
  .mainsection.bnk_sctn th {
    font-size: 14px;
  }
  .strml_div.stml_arw::after {
    height: 17rem;
    left: -58%;
  }
  .strmln_rght {
    width: 12rem;
  }
  .strmln_innr {
    padding: 0 3%;
  }
  .strml_div {
    min-height: 223px;
  }
  .strml_div:last-child::before {
    height: 249px;
  }
  .logo_outr {
    width: 27rem;
  }
  .header .menu-link {
    font-size: 15px;
  }
  .hm.cmn_btn {
    font-size: 16px;
    padding: 12px 18px;
  }
  .get_btn .cmn_btn {
    font-size: 16px;
  }
  .get_started_bg.cmn_hdr p {
    margin: 0px 0;
  }
}

/* ==================<<<<<>>>>> ================== */

@media (max-width: 1080px) {
  .bnr_sec h1 {
    font-size: 5rem;
  }

  /* ayan dey 20.02.23  */
  .pflex {
    flex-direction: column;
  }

  .pflex .c {
    width: 100%;
  }

  /* ayon paul start /02.08.2023 */

  .pg1-prt-2-lwr-inrfld.bnk1a {
    width: 23%;
  }

  .bnk4_frm_grp {
    width: 53% !important;
  }

  .form-group.bnk4 {
    width: 80%;
  }

  .pg1-prt-2-lwr-inrfld.bnk1a {
    width: 47%;
  }

  .rghtTp_hdr {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .bank_content {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  /* ayan dey 26.08.23  */
  .bnk_add_grp .nd3-a {
    top: 37px;
  }
  .lst_rgt_pbuild {
    display: none;
  }
  .btmh2 {
    font-size: 9.9rem;
  }
  .vdo-inr::before {
    width: 38.5rem;
    height: 47.7rem;
    right: -34%;
  }

  .vdo-inr::after {
    width: 38.5rem;
    height: 47.7rem;
    left: -35%;
  }
  .bnk_profile_page {
    width: 75%;
  }
  .strml_div {
    min-height: 249px;
  }
  .strml_div:last-child::before {
    height: 273px;
  }
  .hm_ftr_icon_key {
    width: 60px;
  }
}
/* ==================<<<<<>>>>> ================== */

@media (max-width: 1024px) {
  .other_ope_dtls.bg_grey .form-group,
  .form-input-group,
  .parent_forminput_group .form-group {
    width: 100%;
  }

  .parent_forminput_group .form-group {
    justify-content: space-between;
  }

  .iform-group {
    margin: 10px 0;
  }

  .cmn_hdr h2 {
    font-size: 2.5rem;
  }

  .mainsection .content {
    margin-left: 16rem;
  }

  #checkout-progress {
    margin-bottom: 3rem;
  }

  /* ayon paul start /02.08.2023 */

  .pg1-prt-2-lwr-inrfld.bnk1a {
    width: 24%;
  }

  .pg1-prt-1-inrfld.bnkpge input[type="radio"] {
    width: 2rem;
    height: 2rem;
  }

  .bnk4_frm_grp {
    width: 57% !important;
  }

  .pg1-prt-2-lwr-inrfld.bnk1a {
    width: 47%;
  }

  .bnk_add_grp p {
    margin-bottom: 20px;
  }

  .form-group.bnk4 {
    width: 100%;
    justify-content: space-between;
    gap: 0;
  }
  .ftr_hdng h4 {
    font-size: 16px;
  }
  .ftr_lists ul li a,
  .ftr_lists p {
    font-size: 13px;
  }
}

/* ==================<<<<<>>>>> ================== */

@media (max-width: 991px) {
  .mainsection .content {
    margin-left: 3rem;
  }

  .form-group {
    width: 100%;
  }

  /* ankur 20.7.23  */
  .bnr_img {
    display: none;
  }

  .form_sec {
    position: relative;
    top: inherit;
    right: inherit;
    max-width: 100%;
    margin: 5rem auto;
    width: 70%;
    border: 1rem solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #b38337, #e6c354, #b38337);
  }

  header.sign_up_header {
    padding-inline: 20px;
  }

  .bnr_hdng {
    text-align: center;
    background: linear-gradient(to right, #b38337, #e6c354, #b38337);
    padding: 5rem 0;
  }

  .bnr_sec h1 {
    padding: 0;
    color: #fff;
  }

  .form_sec:after {
    display: none;
  }

  /* changes */

  .pg1-prt-2-end-prtfld {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }

  /* ayon paul start /02.08.2023 */

  .pg1-prt-2-lwr-inrfld.bnk1a {
    width: 26%;
  }

  .pg1-prt-2-lwr-inrfld.bnk1a {
    width: 47%;
  }

  .bnk4_frm_grp {
    width: 60% !important;
  }

  /* ayan dey 03.08.23  */
  .wmcontent {
    width: auto !important;
  }
  .vdo-inr {
    height: 43.8rem;
    width: 80rem;
  }
  #play,
  #close {
    height: 7.5rem;
    width: 7.5rem;
    font-size: 2.5rem;
  }
  .hiwrks_box {
    padding: 3rem;
  }
  .grd-clnt-row {
    width: auto;
  }
  .left_pbuild_img,
  .left_pbuild_under {
    width: 47.8rem;
    height: 42.3rem;
  }
  .rgt_pbuild {
    width: 56%;
  }
  .ftr_lists ul li {
    gap: 6px;
  }
  .ftr_lists ul li {
    margin-top: 6px;
  }
  .get_btn .cmn_btn {
    font-size: 15px;
  }
  .hm.cmn_btn {
    font-size: 15px;
  }
  .header .menu-inner {
    gap: 1rem;
  }
  .hm_sltn_icon {
    width: 50px;
  }
  .strml_div.stml_arw:first-child::after {
    left: 112%;
  }
  .strml_div.stml_arw:nth-child(3)::after {
    left: -49%;
  }
  .strmln_lft p {
    padding: 12px 0 0 0;
  }
  .strml_div {
    min-height: 237px;
  }
  .strml_div:last-child::before {
    height: 263px;
  }
}

/* ==================<<<<<>>>>> ================== */

@media (max-width: 900px) {
  /* ayon paul 20.07.23  */
  .pg1-prt-2-prt-otrfld {
    gap: 1.6rem;
  }

  .pg1-prt-3-end-inrfld {
    width: 100%;
  }

  .pg1-prt-2-lwr-inrfld:last-child {
    width: 20%;
  }

  .pg1-prt-2-prtfld {
    gap: 8px;
  }

  /* ayon paul start /02.08.2023 */

  .pg1-prt-2-lwr-inrfld.bnk1a {
    width: 30%;
  }

  .pg1-prt-2-lwr-inrfld.bnk1b {
    width: 49% !important;
  }

  .pg1-prt-2-lwr-inrfld.bnk1a {
    width: 47%;
  }

  .bnk4_frm_grp {
    width: 100% !important;
  }
  .header .menu-inner {
    gap: 0.5rem;
  }
  .logo_outr {
    width: 20rem;
  }
  .menu {
    gap: 4px;
  }
  .hm_bnr_txt h1 {
    font-size: 4.5rem;
  }
  .hm.cmn_hdr p {
    font-size: 14px;
  }
  .hm.cmn_hdr h3 {
    font-size: 3rem;
  }
  .hm.cmn_hdr h2 {
    font-size: 4rem;
    padding-bottom: 5px;
  }
  .key_hm {
    margin-top: 10px;
  }
  .hm_key::before,
  .hm_key::after {
    height: 58.5rem;
  }
  .hm_sltn_text.hm.cmn_hdr.wht h5 {
    font-size: 1.7rem;
  }
  .hm_sltn_icon {
    min-height: 70px;
  }
  .hm_sltn_text.hm.cmn_hdr.wht h5 {
    margin: 0px 0 5px;
  }
  .hm_hdr #menu {
    max-height: 280px !important;
  }
  .strmln_rght {
    width: 21rem;
  }
}

/* ==================<<<<<>>>>> ================== */

@media (max-width: 840px) {
  /* ayon paul 20.07.23  */
  .pg1-prt-2-inrfld {
    width: 100%;
  }

  /* ayon paul start /02.08.2023 */

  .pg1-prt-2-lwr-inrfld.bnk1a {
    width: 33%;
  }

  .pg1-prt-2-lwr-inrfld.bnk1a {
    width: 47%;
  }

  /* ayan dey 03.08.23  */
  .form-group.w100 .bnk_add_grp {
    width: auto;
  }
  .parent_hiwrks {
    flex-wrap: wrap;
    row-gap: 5rem;
  }
  .hiwrks_box {
    width: 48%;
  }
  .vdo-inr::before {
    width: 30.5rem;
    height: 40.7rem;
    right: -25%;
  }

  .vdo-inr::after {
    width: 30.5rem;
    height: 40.7rem;
    left: -25%;
  }
  /* ayan dey 14.12.23  */
}

/* ==================<<<<<>>>>> ================== */

@media (max-width: 768px) {
  .fromgroup_hdng,
  .switch_box h4,
  .form-input-group label,
  input[type="number"],
  input[type="text"],
  .button-container .btn,
  .check_form-group label {
    font-size: 15px;
  }

  .form-input-group input[type="radio"] {
    width: 14px;
    height: 14px;
  }

  /* ayon paul 20.07.23  */
  .pg1-prt-3-txt .pg1-prt-3-otrfld:last-child {
    margin-top: 2rem;
  }

  .pg1-prt-2-otrfld {
    row-gap: 2rem;
  }

  .pg1-prt-2-lwr-inrfld {
    width: 100%;
  }

  .pg1-prt-2-lwr-inrfld:last-child {
    width: 22%;
  }

  .pg1-prt-2-prt-otrfld {
    gap: 2.5rem;
  }

  .pg1-prt-2-lwr-inrfld:last-child {
    margin-top: 2rem;
  }

  /* ayon paul start /02.08.2023 */

  .pg1-prt-2-lwr-inrfld.bnk1a {
    width: 100%;
    margin-top: 2rem;
  }

  .pg1-prt-2-lwr-inrfld.bnk1b {
    width: 100% !important;
  }

  .bnk_add_grp label {
    font-size: 15px;
  }

  .bnk_add_grp .form-control {
    font-size: 15px;
  }

  .bnk_add_grp p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .bank_content label {
    font-size: 14px;
  }

  .bank_content .fromgroup_hdng,
  .bank_content .switch_box h4 {
    font-size: 14px;
  }

  .bnk_add_grp p {
    font-size: 14px;
  }

  .bnk_add_grp .form-control {
    font-size: 14px;
  }
  .vdo-inr::before,
  .vdo-inr::after {
    display: none;
  }
  .left_pbuild_img,
  .left_pbuild_under {
    width: 35.8rem;
    height: 31.3rem;
  }
  .cmn_hdr h3 {
    font-size: 5rem;
  }
  .header .menu-inner {
    gap: 4rem;
  }
  .left_signup_cntnt h3 {
    font-size: 3.5rem;
  }
  .cmn_btn,
  .header .menu-link,
  .footer_menu ul li a {
    font-size: 14px;
  }
  .parent_signup {
    height: 275px;
  }
  .gt-tch-otr-row {
    flex-direction: column;
    row-gap: 3rem;
  }
  .gt-tch-otr-col {
    width: 100%;
  }
  .grl-clnt-col {
    width: 24%;
  }
  .grd-clnt-row {
    justify-content: center;
    gap: 1rem;
  }
  .container {
    padding: 0px 10px;
  }
  .btmh2 {
    font-size: 6vw;
  }
  .gt-tch-frm-hdng {
    text-align: center;
  }
  .gt-tch-otr-col .left_pbuild {
    margin-inline: auto;
  }
  .grd-clnt-sec {
    padding: 5.5rem 0 26.6rem;
  }
  .nav-menu {
    justify-content: center;
  }
  .header .menu-inner {
    gap: 1rem;
  }
  .logo_outr {
    width: 18rem;
  }
  .mrktng_innr {
    gap: 48px;
  }
}

/* ==================<<<<<>>>>> ================== */

@media (max-width: 767px) {
  /* Navbar */

  .cus_nav_innr {
    position: relative;
  }

  .cus_nav_outr .container {
    padding: 0;
  }

  .logo_area {
    padding-left: 20px;
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .nav_area ul li {
    margin: 10px 0;
  }

  /* ankur 20.7.23  */
  .form_sec {
    width: 80%;
  }

  /* Ankur 03.08.23 */

  .dash_board {
    position: relative;
    width: 100%;
    display: none;
  }

  .bnk_sctn {
    width: 100%;
  }

  .ttl_cntnt {
    display: block;
  }

  .dash_board {
    top: 0;
  }

  .dash_board .logo {
    display: none;
  }

  .rspnsv_hdr {
    display: flex;
  }

  .prflDrpDwn .drop-down .options ul {
    min-width: 95px;
  }

  .nav_bar ul li .nav-menu p {
    font-size: 13px;
  }

  .nav_bar {
    margin-top: 3rem;
  }

  .drpdwnLink {
    font-size: 13px;
  }

  .drop_dwn_cntnt {
    padding-left: 9rem;
  }

  /* ayon paul change / 18.10.2023 */

  .mainsection.bnk_sctn td,
  .mainsection.bnk_sctn th {
    font-size: 13px;
  }

  .mainsection.bnk_sctn .profile_div .col-md-5 .labels {
    font-size: 15px;
  }

  .mainsection.bnk_sctn .profile_div .col-md-5 .form-control {
    font-size: 14px;
  }

  .mainsection.bnk_sctn .profile_div .col-md-5 .prfl-hdng {
    font-size: 18px;
  }
  /* ayan dey 15.11.23  */
  .header {
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;
  }
  .header .hamburger {
    display: block;
    opacity: 1;
    visibility: visible;
    position: absolute;
    right: 2rem;
    top: 3rem;
  }
  .header .menu {
    width: 100%;
    max-height: 0rem;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.35s ease;
    background-color: #e6c354;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9999;
  }
  .header .menu.active {
    opacity: 1;
    visibility: visible;
  }
  .header .menu-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem 0;
  }
  .header .menu-item {
    padding: 0;
    margin: 0;
    border: none;
  }
  .header .menu-item.active {
    border: none;
    outline: none;
  }
  .container {
    padding: 0;
  }
  .header .navigation_bar {
    padding-inline: 0;
  }
  /* ayan dey 16.11.23  */
  .parent_build {
    flex-direction: column-reverse;
    row-gap: 3rem;
  }
  .rgt_pbuild {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
  .grd-clnt-sec::after {
    height: 24rem;
  }
  .parent_signup {
    height: 27rem;
  }
  .left_signup_cntnt {
    margin-left: 20px;
  }
  /* ayan dey 14.12.23  */
  .swal-title {
    font-size: 14px;
  }
  .nav-menu {
    gap: 5rem;
  }
  .hm_hdr #menu {
    display: block;
  }
  .hm_hdr .menu-link:hover {
    color: #000;
  }
  .srch_icon {
    text-align: center;
    padding-bottom: 10px;
  }
  .logo_outr {
    width: 27rem;
  }
  .hm_bnr_txt.hm.cmn_hdr {
    padding-left: 20px;
  }
  .hm_bnr_txt h1 {
    padding-top: 10px;
  }
  .hm_fetr_dtl {
    display: block;
  }
  .hm_fetr_part_hdng {
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
  }
  .hm_fetr_part {
    padding: 20px;
    height: auto;
    width: 90%;
    margin: 10px auto;
  }
  .hm_fetr_txt.hm.cmn_hdr h4 br,
  .hm_fetr_txt.hm.cmn_hdr p br {
    display: none;
  }
  .hm_ftr_icon {
    width: 9.4rem;
  }
  .hm_key::before,
  .hm_key::after {
    display: none;
  }
  .cmn_pdding {
    padding: 5rem 0;
  }
  .hm_key .hm_fetr_part {
    padding: 20px;
  }
  .hm_fetr_txt.hm.cmn_hdr h6 br {
    display: none;
  }
  .hm_key .hm_fetr_txt.hm.cmn_hdr h6 {
    margin-top: 10px;
  }
  .hm_solution {
    background-size: cover;
  }
  .hm_sltn_dtl {
    display: block;
  }
  .hm_sltn_part::after {
    display: none;
  }
  .hm_sltn_part {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .hm_sltn_icon {
    width: 50px;
    margin: auto;
  }
  .strmln::after,
  .strml_div.stml_arw::after {
    display: none;
  }
  .strmln_otr {
    width: 100%;
  }
  .strml_div:last-child {
    position: relative;
    right: 0;
    width: 90%;
    margin: auto;
    gap: 20px;
  }
  .strmln_rght {
    width: 7rem;
  }
  .strml_div {
    min-height: auto;
  }
  .strml_div:last-child::before {
    display: none;
  }
  .strml_div {
    width: 90%;
    margin: auto;
    height: auto;
    justify-content: space-between;
  }
  .strml_div:nth-child(5) {
    margin-right: auto;
  }
  .hm_efcncy .hm_fetr_part {
    width: 90%;
  }
  .side_font h4 {
    display: none;
  }
  .mrktng_innr {
    display: block;
  }
  .mrktng_rght {
    width: 100%;
    margin-top: 7rem;
    text-align: center;
  }
  .mrkt_div {
    width: 90%;
    margin: 10px auto;
  }
  .mrkt__img {
    margin: auto;
  }
  .mrktng_lft {
    width: 90%;
    margin: auto;
  }
  .mrktng_lft::after {
    left: auto;
    right: 181px;
  }
  .hm.cmn_hdr h2 {
    font-size: 3rem;
  }
  .get_btn .cmn_btn {
    font-size: 14px;
  }
  .hm.cmn_btn {
    font-size: 14px;
  }
  .get_started_bg {
    padding: 3rem;
  }
  .get_started {
    margin-bottom: -16rem;
  }
  .mrktng_sec.cmn_pdding {
    padding-bottom: 0;
  }
  .tp_ftr {
    display: block;
  }
  .ftr_list {
    width: 100%;
    text-align: center;
    padding: 0 20px;
  }
  .ftr_aftr_img {
    display: none;
  }
  .ftr_logo.logo_outr {
    margin: auto;
    padding-bottom: 20px;
  }
  .ftr_list:nth-child(2) {
    width: 100%;
  }
  .ftr_aftr {
    display: none;
  }
  .ftr_lists ul li {
    justify-content: center;
  }
  .ftr_lists {
    margin-bottom: 10px;
  }
  .ftr_hdng h4 {
    padding-bottom: 0;
  }
  .ftr_lists p {
    padding-top: 10px;
  }
  .btm_ftr {
    display: block;
  }
  .ftr_rgft ul {
    justify-content: center;
  }
  .ftr_lft.ftr_lists p {
    text-align: center;
  }
  .tp_ftr {
    margin-top: 11rem;
  }
  .hm_bnr_txt.hm.cmn_hdr.wht p {
    width: 100%;
  }
}

/* ==================<<<<<>>>>> ================== */

@media (max-width: 667px) {
  /* ayon paul 20.07.23  */
  .pg1-mn-otr label,
  .pg3-mn-otr label {
    font-size: 13px;
  }

  .pg1-prt-2-inrfld .form-control {
    font-size: 13px;
  }

  .pg1-prt-2-end-prtfld span {
    font-size: 13px;
  }

  .pg1-prt-2-prt-otrfld {
    gap: 1.5rem;
  }

  .pg1-prt-2-prtfld {
    gap: 7px;
  }

  .pg3-mn-inrfld input[type="checkbox"] {
    width: 13px;
    height: 13px;
  }

  .cmn_hdr p {
    font-size: 13px;
  }

  /* ayon paul start / 03.08.2023 */

  .bnk_add_grp p {
    font-size: 13px;
  }

  .bank_content label {
    font-size: 13px;
  }

  .bank_content .fromgroup_hdng,
  .bank_content .switch_box h4 {
    font-size: 13px;
  }

  .bnk_add_grp p {
    font-size: 13px;
  }

  .bnk_add_grp .form-control {
    font-size: 13px;
  }

  .pg3-mn-inrfld label {
    font-size: 13px !important;
  }
  /* ayan dey 22.11.23  */
  .tptc_total_bx {
    width: 100%;
    text-align: center;
    min-height: 100px;
  }
  .tptc_total_bx h4 {
    min-height: auto;
  }
  .accept_btn,
  .declined_btn {
    font-size: 13px;
  }
  .mrktng_lft::after {
    display: none;
  }
  .hm_fetr_txt.hm.cmn_hdr h4 {
    font-size: 2.5rem;
  }
}

/* ==================<<<<<>>>>> ================== */

@media (max-width: 600px) {
  /* ayon paul 20.07.23  */
  .pg1-prt-2-lwr-inrfld:last-child {
    width: 30%;
  }

  .rghtTp_hdr .srch_br {
    width: 45rem;
  }

  .pg1-prt-2-lwr-inrfld.bnk1a {
    width: 100%;
  }
  /* ayan dey 14..12.23 */
  .tabs .tab {
    font-size: 14px;
  }
  .bnk_profile_page {
    width: 95%;
  }
}

/* ==================<<<<<>>>>> ================== */

@media (max-width: 575px) {
  .other_ope_dtls.bg_grey .form-group,
  .form-input-group {
    width: 100%;
    flex-wrap: wrap;
  }

  .other_ope_dtls.bg_grey .form-group .fromgroup_hdng {
    width: 100%;
  }

  .mainsection .content {
    margin-left: 2rem;
  }

  .button-container {
    margin: 40px auto 40px;
  }

  /* ankur 20.7.23  */
  .form_sec {
    width: calc(100% - 40px);
  }

  .bnr_sec h1 {
    font-size: 4rem;
  }

  .sgn_up_cntnt .inpt_bx input {
    padding: 0 1rem;
  }

  .switch_box {
    width: 94px;
  }

  /* ayon paul 20.07.23  */
  .pg1-prt-3-txt h6 {
    padding-bottom: 0;
  }

  .pg1-prt-2-txt h6 {
    padding-bottom: 0;
  }

  .pg1-prt-3-txt .pg1-prt-3-otrfld:last-child {
    margin-top: 1.5rem;
  }

  .pg1-prt-2-otrfld {
    row-gap: 1.5rem;
  }

  .pg1-prt-2-lwr-inrfld:last-child {
    margin-top: 1.5rem;
  }

  .pg1-prt-1-txt {
    padding: 3rem;
  }

  .pg1-prt-2-txt {
    padding: 3rem;
  }

  .pg1-prt-3 {
    padding: 3rem;
  }

  .pg3-mn-inr {
    padding: 3rem;
  }

  /* ayan dey 20.07.23 */
  .outr_finptgrp .form-group {
    width: 100%;
  }

  .rghtTp_hdr {
    flex-direction: column;
    align-items: end;
  }

  .rghtTp_hdr .srch_br {
    width: 100%;
  }

  #checkout-progress .progress-bar .step::after {
    width: 25px;
    height: 25px;
  }

  /* ayon paul change / 18.10.2023 */

  .mainsection.bnk_sctn .profile_div .col-md-5 {
    width: 70%;
  }
  /* ayan dey 16.11.23  */
  .grl-clnt-col {
    width: 31%;
  }
  .build::before {
    display: none;
  }
  .rgt_signup .left_signup_cntnt {
    padding-left: 0;
  }
  .left_signup_cntnt h3 {
    font-size: 3rem;
  }
  .cmn_btn,
  .header .menu-link,
  .footer_menu ul li a {
    font-size: 13px;
  }

  .cmn_hdr h3 {
    font-size: 4rem;
  }
  .vdo-inr {
    height: auto;
    width: 85%;
    margin-inline: auto;
  }
  /* ayan dey 14.12.23  */
  .swal-button {
    font-size: 12px;
  }
  .prflDtl {
    gap: 5px;
  }
  .prflDtl p {
    font-size: 14px;
  }
  .nav-menu {
    justify-content: center;
  }
  .hm_bnr_txt h1 {
    font-size: 3.5rem;
  }
}

/* ==================<<<<<>>>>> ================== */

@media (max-width: 484px) {
  .fromgroup_hdng,
  .switch_box h4,
  .form-input-group label,
  input[type="number"],
  input[type="text"],
  .button-container .btn,
  .check_form-group label {
    font-size: 14px;
  }

  /* ayon paul 20.07.23  */
  .pg1-prt-2-lwr-inrfld:last-child {
    width: 45%;
  }

  .pg1-prt-2-end-inrfld {
    gap: 1.5rem;
  }

  /* ayon paul start / 03.08.2023 */

  .pg1-prt-2-lwr-inrfld.bnk1a {
    width: 100%;
  }
  /* ayan dey 16.11.23  */
  .gt-tch-frm-ttl {
    flex-direction: column;
    position: relative;
    padding-bottom: 50px;
  }
  .gt-tch-frm-lft,
  .gt-tch-frm-rght {
    width: 100%;
  }
  .gt-tch-sb-btn {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 1;
  }
  .form-control::placeholder {
    font-size: 13px;
  }
  .left_signup,
  .rgt_signup {
    width: 100%;
    text-align: center;
    padding: 20px 0;
  }
  .parent_signup {
    flex-direction: column;
    height: auto;
  }
  .left_signup_cntnt {
    position: inherit;
    transform: translateY(0);
  }
  .signup {
    background: none !important;
  }
  .left_signup {
    background-image: url(../images/left_signup.jpg);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .rgt_signup {
    background-image: url(../images/rgt_signup.jpg);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 30px;
    height: 3px;
  }
  .hamburger-inner::before {
    top: -7px;
  }
  .hamburger-inner::after {
    bottom: -7px;
  }
  .howitworks {
    padding-top: 5rem;
    padding-bottom: 25rem;
  }
  .gt-tch-frm-ttl {
    gap: 0;
  }
  .get_started {
    width: 100%;
  }
  .hm_bnr_img {
    height: 300px;
  }
  .hm_bnr_img img {
    height: 100%;
  }
  .hm.cmn_hdr h6 {
    font-size: 2rem;
  }
  .hm_ftr_icon_key {
    width: 40px;
  }
  .hm_ftr_icon {
    width: 7.4rem;
  }
}

/* ==================<<<<<>>>>> ================== */

@media (max-width: 430px) {
  .hm.cmn_hdr h2 {
    font-size: 2.5rem;
  }
  .frm_radio_prnt {
    gap: 3rem;
  }

  .bg_grey {
    padding: 5rem 2rem;
  }

  .iform-group {
    margin: 0;
  }

  #checkout-progress .progress-bar .step span,
  #checkout-progress .progress-bar .step {
    font-size: 13px;
  }

  .button-container .btn {
    padding: 13px 13rem;
  }

  /* ankur 20.7.23 */
  header.sign_up_header {
    padding: 2rem 10px;
  }

  header.sign_up_header .logo {
    width: 150px;
  }

  .form_sec {
    width: calc(100% - 20px);
  }

  .form_sec {
    border: 3px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #b38337, #e6c354, #b38337);
    padding: 2rem 2rem 1rem;
    margin: 2rem auto;
  }

  .frm_hdng h3 {
    padding-bottom: 3rem;
  }

  /* ayon paul 20.07.23  */
  .cmn_hdr h6 {
    font-size: 2.2rem;
  }

  .pg1-prt-1-txt {
    padding: 2rem;
  }

  .pg1-prt-2-txt {
    padding: 2rem;
  }

  .pg1-prt-3 {
    padding: 2rem;
  }

  .pg3-mn-inr {
    padding: 2rem;
  }

  .pg3-mn-inrfld {
    gap: 8px;
  }

  /* ayon paul start / 03.08.2023 */

  .form-group.bnk4 {
    row-gap: 10px;
  }
  /* ayan dey 16.11.23  */
  .left_signup_cntnt h3 {
    font-size: 20px;
  }
  .cmn_btn {
    padding: 7px 12px;
  }
  .grl-clnt-col {
    width: 45%;
  }
  .vdo-inr {
    width: 95%;
  }
  .hiwrks_box {
    width: 90%;
    margin: auto;
    text-align: center;
  }
  .hwibx_img {
    margin-inline: auto;
  }
  /* ayan dey 22.11.23  */
  .prnt_btm_search {
    flex-direction: column;
    row-gap: 10px;
  }
  /* ayan dey 14..12.23 */
  .tabs .tab {
    font-size: 12px;
  }
  .bank_profile_from {
    width: 95%;
  }
  .button-container .btn {
    padding: 8px 8rem;
  }
  .form__group {
    margin-bottom: 0;
  }
}

/* ==================<<<<<>>>>> ================== */

@media (max-width: 390px) {
  .bnr_sec h1 {
    font-size: 3.5rem;
  }

  /* ayon paul 20.07.23  */
  .pg1-prt-3-end-inrfld {
    gap: 1.5rem;
  }

  .pg1-prt-3-end-inrfld {
    gap: 1.5rem;
  }

  .toggle-bg {
    box-shadow: none !important;
  }
  /* ayon paul change / 18.10.2023 */

  .mainsection.bnk_sctn .profile_div .col-md-5 {
    width: 90%;
  }
}

/* ==================<<<<<>>>>> ================== */

@media (max-width: 375px) {
  .fromgroup_hdng,
  .switch_box h4,
  .form-input-group label,
  input[type="number"],
  input[type="text"],
  .button-container .btn,
  .check_form-group label {
    font-size: 13px;
  }

  span.slider {
    width: 50px;
    height: 20px;
    top: 5px;
  }

  .slider::before {
    height: 12px;
    width: 12px;
  }

  /* changes */
  .pg1-prt-2-end-prtfld span:last-of-type {
    padding-left: 10px;
  }

  /* ayon paul start / 03.08.2023 */

  .form-group.bnk4 {
    row-gap: 5px;
  }
  /* ayan dey 16.11.23  */

  .hwibx_img {
    margin-inline: auto;
  }
}

/* ==================<<<<<>>>>> ================== */

@media (max-width: 360px) {
}
.hm_bnr_img img{width:100%}
/* ==================<<<<<>>>>> ================== */
